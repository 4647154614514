import round from 'lodash/round';
import BadRequestError from './exceptions/BadRequestError';
import {
	InvoiceTypeAPI,
	PaymentTypeAPI,
	TransactionTypeAPI,
} from '../constants/journal';
import {
	convertData,
	fiscalizationItemsToInvoiceRequestItems,
	formatReceiptDelivery,
	getAdvanceAmount,
	getAdvanceItemsSum,
	getAdvancePaymentsByLabel,
	getAdvancePaymentsSum,
	getChangeAmount,
	getDiscountedAmount,
	getFirstAdvanceSaleReceipt,
	getLastAdvanceReceipt,
	getLastAdvanceSaleReceipt,
	getPaymentsSum,
	getTotalsByLabelUnknown,
	isTaxFree,
} from './lib';
import stores from '../stores/index.mobx';
import { flowResult } from 'mobx';
import last from 'lodash/last';
import clamp from 'lodash/clamp';
import { bignumber, evaluate } from 'mathjs';

export async function createReceipt({ body }) {
	const { receipts } = stores;

	const paymentsSum = getPaymentsSum(body.payments);

	// if (paymentsSum === 0) {
	// 	throw new BadRequestError(
	// 		BadRequestError.ERROR_BAD_REQUEST_NO_PAYMENT_METHOD_SPECIFIED
	// 	);
	// }
	const taxFree = isTaxFree(body.buyerCostCenterId);

	const mappedItems = fiscalizationItemsToInvoiceRequestItems(
		body.items,
		body.discount,
		taxFree
	);
	const discountedAmount = getDiscountedAmount(mappedItems);

	const changeAmount = getChangeAmount(
		body.transactionType,
		body.payments,
		discountedAmount,
		0,
		body.unknownAmountAdvance
	);

	if (changeAmount > body.payments[PaymentTypeAPI.CASH]) {
		throw new BadRequestError(
			BadRequestError.ERROR_BAD_REQUEST_CHANGE_AMOUNT_GREATER_THAN_CASH_AMOUNT
		);
	}
	if (
		body.invoiceType !== InvoiceTypeAPI.ADVANCE &&
		paymentsSum < discountedAmount
	) {
		throw new BadRequestError(
			BadRequestError.ERROR_BAD_REQUEST_SUM_PAYMENT_METHODS_LESS_THAN_TOTAL
		);
	}

	// if (
	// 	body.invoiceType === InvoiceTypeAPI.ADVANCE &&
	// 	body.transactionType === TransactionTypeAPI.REFUND &&
	// 	(body.referentDocumentNumber || '')
	// 		.toLowerCase()
	// 		.startsWith('xxxxxxxx-xxxxxxxx-')
	// ) {
	// 	throw new BadRequestError(
	// 		BadRequestError.ERROR_BAD_REQUEST_UNSUPPORTED_OPERATION_OLD_ADVANCE_REFUND
	// 	);
	// }

	const items =
		body.invoiceType === InvoiceTypeAPI.ADVANCE
			? getAdvancePaymentsByLabel(
					'normal',
					body.unknownAmountAdvance,
					discountedAmount,
					paymentsSum,
					changeAmount,
					mappedItems,
					body.advanceSpecification
			  )
			: mappedItems;

	const advanceItems =
		body.invoiceType === InvoiceTypeAPI.ADVANCE ? mappedItems : undefined;

	const baseReceiptDelivery = body.receiptDelivery;
	const receiptDelivery = formatReceiptDelivery(baseReceiptDelivery);

	delete body.receiptDelivery;

	const values = {
		request: convertData({
			...body,
			payments: {
				...body.payments,
				[PaymentTypeAPI.CASH]: round(
					evaluate('cash - changeAmount', {
						cash: bignumber(body.payments[PaymentTypeAPI.CASH] || 0),
						changeAmount: bignumber(changeAmount),
					}).toNumber(),
					2
				),
			},
			items,
			advanceItems,
			change: changeAmount,
		}),
		includeSignature: false,
		receiptDelivery,
	};

	const response = await flowResult(receipts.apiFiscalizeNormal(values));

	return response.receipts[0];
}

export async function addAdvancePayment({ body, params }) {
	const { id } = params;
	const { receipts } = stores;

	const receipt = await flowResult(stores.receipts.fetchSingle(id));

	const firstAdvanceSale = getFirstAdvanceSaleReceipt(
		receipt.connectedReceipts
	);

	if (!firstAdvanceSale) {
		throw new BadRequestError(
			BadRequestError.ERROR_BAD_REQUEST_UNSUPPORTED_INVOICE_TYPE
		);
	}

	const lastReceipt = last(
		receipt.connectedReceipts.filter((cr) => !cr.void && !cr.voids)
	);
	if (
		lastReceipt.invoiceType === InvoiceTypeAPI.NORMAL &&
		lastReceipt.transactionType === TransactionTypeAPI.SALE
	) {
		throw new BadRequestError(
			BadRequestError.ERROR_BAD_REQUEST_ADVANCE_ALREADY_CLOSED
		);
	}

	const { unknownAmountAdvance } = firstAdvanceSale;

	const advanceAmount = getAdvanceAmount(receipt.connectedReceipts);
	const advanceItemsSum = getAdvanceItemsSum(firstAdvanceSale.advanceItems);

	if (!unknownAmountAdvance && advanceAmount >= advanceItemsSum) {
		throw new BadRequestError(
			BadRequestError.ERROR_BAD_REQUEST_ADVANCE_TOTAL_AMOUNT_PAID
		);
	}

	const paymentsSum = getPaymentsSum(body.payments);

	if (paymentsSum === 0) {
		throw new BadRequestError(
			BadRequestError.ERROR_BAD_REQUEST_NO_PAYMENT_METHOD_SPECIFIED
		);
	}

	const discountedAmount = getDiscountedAmount(receipt.receiptItems);

	const changeAmount = getChangeAmount(
		body.transactionType,
		body.payments,
		discountedAmount,
		0,
		unknownAmountAdvance
	);

	if (changeAmount > body.payments[PaymentTypeAPI.CASH]) {
		throw new BadRequestError(
			BadRequestError.ERROR_BAD_REQUEST_CHANGE_AMOUNT_GREATER_THAN_CASH_AMOUNT
		);
	}

	// if (
	// 	body.invoiceType !== InvoiceTypeAPI.ADVANCE &&
	// 	paymentsSum < discountedAmount &&
	// 	!unknownAmountAdvance
	// ) {
	// 	throw new BadRequestError(
	// 		BadRequestError.ERROR_BAD_REQUEST_SUM_PAYMENT_METHODS_LESS_THAN_TOTAL
	// 	);
	// }

	const advanceItems = receipt.advanceItems.map((item) => ({
		product: {
			id: item.productId,
			price: item.unitPrice,
			ean: item.gtin,
			name: item.name,
			unit: {
				saleUnit: {
					label: item.unit,
					isPieceUnitOfMeasure: item.isPieceUnitOfMeasure,
				},
			},
			sku: item.sku,
		},
		quantity: item.quantity,
		taxRateLabel: item.taxLabels[0],
		finalPrice: item.unitPrice,
		discount: item.discount,
	}));

	const items = await getAdvancePaymentsByLabel(
		'add-advance-payment',
		unknownAmountAdvance,
		discountedAmount,
		paymentsSum,
		changeAmount,
		receipt.advanceItems,
		body.advanceSpecification,
		receipt
	);

	const baseReceiptDelivery = body.receiptDelivery;
	const receiptDelivery = formatReceiptDelivery(baseReceiptDelivery);

	delete body.receiptDelivery;

	const values = {
		request: convertData({
			...body,
			invoiceType: InvoiceTypeAPI.ADVANCE,
			transactionType: TransactionTypeAPI.SALE,
			referentDocumentNumber: receipt.invoiceNumber,
			referentDocumentDT: receipt.sdcTime,
			buyerId: body.buyerId || receipt.buyerId,
			buyerCostCenterId: body.buyerCostCenterId || receipt.buyerCostCenterId,
			payments: {
				...body.payments,
				[PaymentTypeAPI.CASH]: round(
					evaluate('cash - changeAmount', {
						cash: bignumber(body.payments[PaymentTypeAPI.CASH] || 0),
						changeAmount: bignumber(changeAmount),
					}).toNumber(),
					2
				),
			},
			items,
			advanceItems,
			change: changeAmount,
		}),
		includeSignature: false,
		receiptDelivery,
	};

	const lastAdvanceReceipt = getLastAdvanceReceipt(receipt.connectedReceipts);

	if (lastAdvanceReceipt) {
		values.request.referentDocumentNumber = lastAdvanceReceipt.invoiceNumber;
		values.request.referentDocumentDT = lastAdvanceReceipt.sdcTime;
	}

	const response = await flowResult(
		receipts.apiFiscalizeAddAdvancePayment(values)
	);

	return response.receipts[0];
}

export async function closeAdvance({ body, params }) {
	const { id } = params;
	const { receipts } = stores;

	const receipt = await flowResult(stores.receipts.fetchSingle(id));

	const firstAdvanceSale = getFirstAdvanceSaleReceipt(
		receipt.connectedReceipts
	);

	if (
		!firstAdvanceSale &&
		!(
			receipt.transactionType === TransactionTypeAPI.REFUND &&
			receipt.invoiceType === InvoiceTypeAPI.ADVANCE
		)
	) {
		throw new BadRequestError(
			BadRequestError.ERROR_BAD_REQUEST_UNSUPPORTED_INVOICE_TYPE
		);
	}

	let isOldAdvance = false;

	if (
		!firstAdvanceSale &&
		receipt.transactionType === TransactionTypeAPI.REFUND &&
		receipt.invoiceType === InvoiceTypeAPI.ADVANCE
	) {
		isOldAdvance = true;
	}

	const lastReceipt = last(
		receipt.connectedReceipts.filter((cr) => !cr.void && !cr.voids)
	);

	if (
		lastReceipt.invoiceType === InvoiceTypeAPI.NORMAL &&
		lastReceipt.transactionType === TransactionTypeAPI.SALE
	) {
		throw new BadRequestError(
			BadRequestError.ERROR_BAD_REQUEST_ADVANCE_ALREADY_CLOSED
		);
	}

	const { unknownAmountAdvance } = isOldAdvance
		? { unknownAmountAdvance: false }
		: firstAdvanceSale;

	const paymentsSum = getPaymentsSum(body.payments);
	const advanceAmount = isOldAdvance
		? receipt.paymentTotal
		: getAdvanceAmount(receipt.connectedReceipts);

	const totalAmount = unknownAmountAdvance
		? advanceAmount
		: (isOldAdvance ? receipt : firstAdvanceSale).advanceItems.reduce(
				(acc, item) =>
					round(
						evaluate('acc + quantity * unitPrice', {
							acc: bignumber(acc),
							quantity: bignumber(item.quantity),
							unitPrice: bignumber(item.unitPrice),
						}).toNumber(),
						2
					),
				0
		  );

	const remainingAmount = clamp(
		round(totalAmount - advanceAmount, 2),
		0,
		Infinity
	);

	if (paymentsSum === 0 && remainingAmount > 0) {
		throw new BadRequestError(
			BadRequestError.ERROR_BAD_REQUEST_NO_PAYMENT_METHOD_SPECIFIED
		);
	}

	if (paymentsSum < remainingAmount && !unknownAmountAdvance) {
		throw new BadRequestError(
			BadRequestError.ERROR_BAD_REQUEST_SUM_PAYMENT_METHODS_LESS_THAN_TOTAL
		);
	}

	const changeAmount = getChangeAmount(
		TransactionTypeAPI.SALE,
		body.payments,
		totalAmount,
		advanceAmount,
		unknownAmountAdvance
	);

	if (changeAmount > body.payments[PaymentTypeAPI.CASH]) {
		throw new BadRequestError(
			BadRequestError.ERROR_BAD_REQUEST_CHANGE_AMOUNT_GREATER_THAN_CASH_AMOUNT
		);
	}

	// TODO handle previously refunded advance

	const advanceReceipts = receipt.connectedReceipts.filter(
		(cr) => !cr.void && !cr.voids && cr.invoiceType === InvoiceTypeAPI.ADVANCE
	);

	const advancePaymentsByMethod: Record<string, number> = isOldAdvance
		? receipt.payment.reduce((prev, curr) => {
				prev[curr.paymentType] = curr.amount;
				return prev;
		  }, {})
		: advanceReceipts.reduce((acc, cr) => {
				cr.payment.forEach((payment) => {
					acc[payment.paymentType] = round(
						(acc[payment.paymentType] || 0) + cr.transactionType ===
							TransactionTypeAPI.SALE
							? payment.amount
							: -payment.amount,
						2
					);
				});

				return acc;
		  }, {});

	const advanceChangeAmount = advanceReceipts.reduce(
		(acc, cr) => round(acc - cr.paymentChange, 2),
		0
	);

	const lastAdvanceSaleReceipt = getLastAdvanceSaleReceipt(
		receipt.connectedReceipts
	);

	const advanceItems = (
		isOldAdvance ? receipt : lastAdvanceSaleReceipt
	).receiptItems.map((item) => {
		const amount = advanceReceipts.reduce(
			(acc, cr) =>
				round(
					evaluate('acc + totalAmount * multiplier', {
						acc: bignumber(acc),
						totalAmount: bignumber(
							cr.receiptItems.find((ri) => ri.name === item.name)
								?.totalAmount || 0
						),
						multiplier: cr.transactionType === TransactionTypeAPI.SALE ? 1 : -1,
					}).toNumber(),
					2
				),
			0
		);

		return {
			product: {
				id: item.productId,
				price: amount,
				name: item.name,
			},
			quantity: item.quantity,
			taxRateLabel: item.taxLabels[0],
			finalPrice: amount,
		};
	});

	if (!isOldAdvance) {
		Object.entries(advancePaymentsByMethod).forEach(([paymentType, amount]) => {
			if (Number(amount) < 0) {
				let toSubtract = -amount;
				advancePaymentsByMethod[paymentType] = 0;
				Object.entries(advancePaymentsByMethod).forEach(
					([innerPaymentType, innerAmount]) => {
						if (innerPaymentType !== paymentType) {
							if (Number(innerAmount) < toSubtract) {
								toSubtract -= Number(innerAmount);
								advancePaymentsByMethod[innerPaymentType] = 0;
							} else {
								advancePaymentsByMethod[innerPaymentType] -= toSubtract;
								toSubtract = 0;
							}
						}
					}
				);
			}
		});

		advancePaymentsByMethod.cash = round(
			evaluate('cash - changeAmount', {
				cash: bignumber(advancePaymentsByMethod.cash || 0),
				changeAmount: bignumber(advanceChangeAmount),
			}).toNumber(),
			2
		);
	}

	let items = (
		isOldAdvance ? receipt : lastAdvanceSaleReceipt
	).advanceItems.map((item) => ({
		product: {
			id: item.productId,
			price: item.unitPrice,
			ean: item.gtin,
			name: item.name,
			unit: {
				saleUnit: {
					label: item.unit,
					isPieceUnitOfMeasure: item.isPieceUnitOfMeasure,
				},
			},
			sku: item.sku,
		},
		quantity: item.quantity,
		taxRateLabel: item.taxLabels[0],
		finalPrice: item.unitPrice,
		discount: item.discount,
	}));

	const totalsByLabel = getTotalsByLabelUnknown(advanceReceipts);

	if (unknownAmountAdvance) {
		if (
			Object.keys(totalsByLabel).length === items.length &&
			(paymentsSum === 0 || items.length === 1)
		) {
			items = items.map((item) => ({
				...item,
				finalPrice: round(
					evaluate('total + paymentSum', {
						total: bignumber(totalsByLabel[item.taxRateLabel]),
						paymentSum: bignumber(paymentsSum),
					}).toNumber(),
					2
				),
			}));
		} else if (!body.items) {
			throw new BadRequestError(
				BadRequestError.ERROR_BAD_REQUEST_ADVANCE_ITEMS_MISSING
			);
		} else {
			items = await fiscalizationItemsToInvoiceRequestItems(
				body.items.filter((item) => item.totalAmount > 0)
			);
		}
	}

	const baseReceiptDelivery = body.receiptDelivery;
	const receiptDelivery = formatReceiptDelivery(baseReceiptDelivery);

	delete body.receiptDelivery;

	let refundReceipt;

	const values = {
		request: convertData({
			...body,
			invoiceType: InvoiceTypeAPI.ADVANCE,
			transactionType: TransactionTypeAPI.REFUND,
			buyerId: body.buyerId || receipt.buyerId,
			buyerCostCenterId: body.buyerCostCenterId || receipt.buyerCostCenterId,
			payments: advancePaymentsByMethod,
			items: advanceItems,
			change: changeAmount,
		}),
		includeSignature: false,
		receiptDelivery,
		lastAdvanceSale: undefined,
	};

	if (!isOldAdvance) {
		if (lastAdvanceSaleReceipt) {
			values.request.referentDocumentNumber =
				lastAdvanceSaleReceipt.invoiceNumber;
			values.request.referentDocumentDT = lastAdvanceSaleReceipt.sdcTime;
		}

		const refundResponse = await flowResult(
			receipts.apiFiscalizeFinalizeAdvanceRefund(values)
		);

		refundReceipt = refundResponse.receipts[0];
	} else {
		refundReceipt = receipt;
	}

	values.request.transactionType = TransactionTypeAPI.SALE;
	values.request.invoiceType = InvoiceTypeAPI.NORMAL;
	values.request.referentDocumentDT = refundReceipt.sdcDateTime;
	values.request.referentDocumentNumber = refundReceipt.invoiceNumber;
	values.request.payments = {
		...body.payments,
		[PaymentTypeAPI.CASH]: round(
			evaluate('cash - changeAmount', {
				cash: bignumber(body.payments[PaymentTypeAPI.CASH] || 0),
				changeAmount: bignumber(changeAmount),
			}).toNumber(),
			2
		),
	};
	values.request.advancePayments = advanceAmount;
	values.request.change = changeAmount;
	values.request.items = items;
	values.lastAdvanceSale = isOldAdvance
		? {
				invoiceNumber: receipt.referentDocumentNumber,
				sdcTime: receipt.referentDocumentDT,
		  }
		: {
				invoiceNumber: lastAdvanceSaleReceipt.invoiceNumber,
				sdcTime: lastAdvanceSaleReceipt.sdcTime,
		  };

	values.request = convertData(values.request);

	const normalResponse = await flowResult(
		receipts.apiFiscalizeFinalizeAdvance(values)
	);

	const normalReceipt = normalResponse.receipts[0];
	if (isOldAdvance) {
		return normalReceipt;
	}
	return [refundReceipt, normalReceipt];
}
export async function copy({ body, params }) {
	const { id } = params;
	const { receipts } = stores;

	const receipt = await flowResult(stores.receipts.fetchSingle(id));

	const payments = receipt.payment.reduce(
		(acc, curr) => ({
			...acc,
			[curr.paymentType]: round(
				evaluate('acc + amount', {
					acc: bignumber(acc[curr.paymentType] || 0),
					amount: bignumber(curr.amount),
				}).toNumber(),
				2
			),
		}),
		{
			[PaymentTypeAPI.CARD]: 0,
			[PaymentTypeAPI.CHECK]: 0,
			[PaymentTypeAPI.CASH]: 0,
			[PaymentTypeAPI.MOBILE_MONEY]: 0,
			[PaymentTypeAPI.WIRE_TRANSFER]: 0,
			[PaymentTypeAPI.VOUCHER]: 0,
		}
	);

	const lastAdvanceSaleReceipt = getLastAdvanceSaleReceipt(
		receipt.connectedReceipts
	);

	let advancePayments;
	let advanceSale;

	const firstAdvanceSale = getFirstAdvanceSaleReceipt(
		receipt.connectedReceipts
	);

	if (
		receipt.invoiceType === InvoiceTypeAPI.NORMAL &&
		receipt.transactionType === TransactionTypeAPI.SALE
	) {
		if (firstAdvanceSale) {
			advancePayments = getAdvancePaymentsSum(receipt);
			advanceSale = lastAdvanceSaleReceipt;
		} else if (
			receipt.connectedReceipts[0].invoiceType === InvoiceTypeAPI.ADVANCE &&
			receipt.connectedReceipts[0].transactionType === TransactionTypeAPI.REFUND
		) {
			advancePayments = receipt.connectedReceipts[0].paymentTotal;
			advanceSale = {
				invoiceNumber: receipt.connectedReceipts[0].referentDocumentNumber,
				sdcTime: receipt.connectedReceipts[0].referentDocumentDT,
			};
		}
	}

	const { unknownAmountAdvance } = firstAdvanceSale || {};

	const advanceAmount = getAdvanceAmount(receipt.connectedReceipts);

	const totalAmount = receipt.totalAmount || 0;

	const changeAmount = getChangeAmount(
		InvoiceTypeAPI.COPY,
		payments,
		totalAmount,
		advanceAmount,
		unknownAmountAdvance
	);

	const baseReceiptDelivery = body.receiptDelivery;
	const receiptDelivery = formatReceiptDelivery(baseReceiptDelivery);

	delete body.receiptDelivery;

	const values = {
		request: convertData({
			...body,
			invoiceType: InvoiceTypeAPI.COPY,
			transactionType: receipt.transactionType,
			referentDocumentNumber: receipt.invoiceNumber,
			referentDocumentDT: receipt.sdcTime,
			buyerId: receipt.buyerId,
			buyerCostCenterId: receipt.buyerCostCenterId,
			payments: {
				...payments,
				cash: payments.cash - changeAmount,
			},
			items: receipt.receiptItems.map((item) => ({
				product: {
					id: item.productId,
					price: item.unitPrice,
					ean: item.gtin,
					name: item.name,
					unit: {
						saleUnit: {
							label: item.unit,
							isPieceUnitOfMeasure: item.isPieceUnitOfMeasure,
						},
					},
					sku: item.sku,
				},
				quantity: item.quantity,
				taxRateLabel: item.taxLabels[0],
				finalPrice: item.unitPrice,
				discount: item.discount,
			})),
			change: receipt.paymentChange,
			posTime: receipt.posTime,
			advanceItems: receipt.advanceItems
				? receipt.advanceItems.map((item) => ({
						product: {
							id: item.productId,
							price: item.unitPrice,
							ean: item.gtin,
							name: item.name,
							unit: {
								saleUnit: {
									label: item.unit,
									isPieceUnitOfMeasure: item.isPieceUnitOfMeasure,
								},
							},
							sku: item.sku,
						},
						quantity: item.quantity,
						taxRateLabel: item.taxLabels[0],
						finalPrice: item.unitPrice,
						discount: item.discount,
				  }))
				: undefined,
			advancePayments,
		}),
		includeSignature: false,
		receiptDelivery,
		lastAdvanceSale: advanceSale,
	};

	if (lastAdvanceSaleReceipt) {
		values.request.referentDocumentNumber =
			lastAdvanceSaleReceipt.invoiceNumber;
		values.request.referentDocumentDT = lastAdvanceSaleReceipt.sdcTime;
	}
	const response = await flowResult(receipts.apiFiscalizeCopy(values));

	return response.receipts[0];
}

export async function refund({ body, params }) {
	const { id } = params;
	const { receipts } = stores;

	const receipt = await flowResult(stores.receipts.fetchSingle(id));

	if (receipt.invoiceType === InvoiceTypeAPI.COPY) {
		throw new BadRequestError(
			BadRequestError.ERROR_BAD_REQUEST_UNSUPPORTED_INVOICE_TYPE
		);
	}

	if (receipt.transactionType === TransactionTypeAPI.REFUND) {
		throw new BadRequestError(
			BadRequestError.ERROR_BAD_REQUEST_UNSUPPORTED_TRANSACTION_TYPE
		);
	}

	const paymentsSum = getPaymentsSum(body.payments);

	const refundedQuantities = receipt.connectedReceipts
		.filter(
			(cr) =>
				cr.transactionType === TransactionTypeAPI.REFUND &&
				[
					InvoiceTypeAPI.NORMAL,
					InvoiceTypeAPI.PROFORMA,
					InvoiceTypeAPI.TRAINING,
				].includes(cr.invoiceType) &&
				!cr.void &&
				!cr.voids
		)
		.reduce((acc, cr) => {
			cr.receiptItems.forEach((item) => {
				acc[item.productId] = round(
					evaluate('acc + quantity', {
						acc: bignumber(acc[item.productId] || 0),
						quantity: bignumber(item.quantity),
					}).toNumber(),
					3
				);
			});
			return acc;
		}, {});

	const prices = receipt.receiptItems.reduce((acc, item) => {
		acc[item.productId] = item.unitPrice;
		return acc;
	}, {});

	const mappedItems = [];
	let totalAmount = 0;

	if (receipt.invoiceType !== InvoiceTypeAPI.ADVANCE) {
		let mappedItems = await fiscalizationItemsToInvoiceRequestItems(
			body.items.map((item) => ({
				...item,
			}))
		);
		mappedItems = mappedItems.map((item) => ({
			...item,
			unitPrice: prices[item.productId],
		}));

		totalAmount = mappedItems.reduce((acc, cur) =>
			round(
				evaluate('acc + quantity * unitPrice', {
					acc: bignumber(acc),
					quantity: bignumber(cur.quantity),
					unitPrice: bignumber(cur.unitPrice),
				}).toNumber(),
				2
			)
		);

		// TODO: handle multiple items with the same productId
		const remainingQuantities = receipt.receiptItems.reduce((acc, item) => {
			acc[item.productId] = round(
				evaluate('quantity - refundedQuantities', {
					quantity: bignumber(item.quantity),
					refundedQuantities: bignumber(
						refundedQuantities[item.productId] || 0
					),
				}).toNumber(),
				3
			);
			return acc;
		}, {});

		if (
			mappedItems.find((item) => {
				return (
					round(
						evaluate('remainingQuantities - quantity', {
							remainingQuantities: bignumber(
								remainingQuantities[item.productId] || 0
							),
							quantity: bignumber(item.quantity),
						}).toNumber(),
						3
					) < 0
				);
			})
		) {
			throw new BadRequestError(
				BadRequestError.ERROR_REFUND_QUANTITY_LARGER_THAN_REMAINING_QUANTITY
			);
		}

		if (paymentsSum < totalAmount) {
			throw new BadRequestError(
				BadRequestError.ERROR_BAD_REQUEST_SUM_PAYMENT_METHODS_LESS_THAN_TOTAL
			);
		}
	} else {
		if (
			receipt.connectedReceipts.find(
				(cr) =>
					cr.invoiceType === InvoiceTypeAPI.NORMAL &&
					cr.transactionType === TransactionTypeAPI.SALE &&
					!cr.void
			)
		) {
			throw new BadRequestError(
				BadRequestError.ERROR_BAD_REQUEST_ADVANCE_ALREADY_CLOSED
			);
		}

		totalAmount = getAdvanceAmount(receipt.connectedReceipts);
	}

	if (paymentsSum === 0 && totalAmount > 0) {
		throw new BadRequestError(
			BadRequestError.ERROR_BAD_REQUEST_NO_PAYMENT_METHOD_SPECIFIED
		);
	}

	if (paymentsSum > totalAmount) {
		throw new BadRequestError(
			BadRequestError.ERROR_BAD_REQUEST_SUM_PAYMENT_METHODS_GREATER_THAN_TOTAL
		);
	}

	const changeAmount = 0;
	const discountedAmount = getDiscountedAmount(mappedItems);

	const items =
		receipt.invoiceType === InvoiceTypeAPI.ADVANCE
			? getAdvancePaymentsByLabel(
					'advance',
					body.unknownAmountAdvance,
					discountedAmount,
					paymentsSum,
					changeAmount,
					receipt.advanceItems,
					body.advanceSpecification,
					receipt
			  )
			: mappedItems;

	const payments = Object.entries(body.payments).reduce(
		(acc, [paymentType, amount]) => ({
			...acc,
			[paymentType]: round(
				evaluate('acc + amount', {
					acc: bignumber(acc[paymentType] || 0),
					amount: bignumber(amount),
				}).toNumber(),
				2
			),
		}),
		{
			[PaymentTypeAPI.CARD]: 0,
			[PaymentTypeAPI.CHECK]: 0,
			[PaymentTypeAPI.CASH]: 0,
			[PaymentTypeAPI.MOBILE_MONEY]: 0,
			[PaymentTypeAPI.WIRE_TRANSFER]: 0,
			[PaymentTypeAPI.VOUCHER]: 0,
		}
	);

	const baseReceiptDelivery = body.receiptDelivery;
	const receiptDelivery = formatReceiptDelivery(baseReceiptDelivery);

	delete body.receiptDelivery;

	const refundValues = {
		request: convertData({
			...body,
			invoiceType: receipt.invoiceType,
			transactionType: TransactionTypeAPI.REFUND,
			referentDocumentNumber: receipt.invoiceNumber,
			referentDocumentDT: receipt.sdcTime,
			payments: {
				...payments,
				cash: round(
					evaluate('cash - changeAmount', {
						cash: bignumber(payments.cash),
						changeAmount: bignumber(changeAmount),
					}).toNumber(),
					2
				),
			},
			items,
			change: 0,
		}),
		includeSignature: false,
		receiptDelivery,
	};
	const refundResponse = await flowResult(
		receipts.apiFiscalizeRefund(refundValues)
	);

	const refundReceipt = refundResponse.receipts[0];
	const createdReceipts = [refundReceipt];

	if (payments[PaymentTypeAPI.CASH] > 0) {
		const copyValues = {
			request: convertData({
				...body,
				invoiceType: InvoiceTypeAPI.COPY,
				transactionType: TransactionTypeAPI.REFUND,
				referentDocumentNumber: refundReceipt.invoiceNumber,
				referentDocumentDT: refundReceipt.sdcDateTime,
				payments: {
					...payments,
					cash: round(
						evaluate('cash - changeAmount', {
							cash: bignumber(payments.cash),
							changeAmount: bignumber(changeAmount),
						}).toNumber(),
						2
					),
				},
				items,
				change: 0,
			}),
			includeSignature: false,
			receiptDelivery,
		};

		const copyResponse = await flowResult(
			receipts.apiFiscalizeCopy(copyValues)
		);

		createdReceipts.push(copyResponse.receipts[0]);
	}

	return createdReceipts;
}
