import { Dayjs } from 'dayjs';
import { round } from 'lodash';
import { computed, flow, observable } from 'mobx';

import { CreateStore } from './Crud.mobx';
import { StockTurnover, StockTurnoverStatus } from './StockTurnover.mobx';
import { ArrayTypeTransformer } from './transformers/ArrayType';
import { DayjsTransformer } from './transformers/Dayjs';
import { bignumber, evaluate } from 'mathjs';

const { Store, Entity } = CreateStore({
	name: 'conversion',
	paginated: true,
	order: 'desc',
});

export enum ConversionType {
	MANUAL = 'manual',
	INVENTORY = 'inventory',
}

export class ConversionItem extends Entity {
	@observable productId?: string;
	@observable convertedProductId?: string;
	@observable warehouseId?: string;
	@observable purchasePrice?: number;
	@observable quantity?: number;
	@observable convertedProductQuantity?: number;
	@observable purchaseAmount?: number;
	@observable oldSalePriceWithVat?: number;
	@observable salePrice?: number;
	@observable salePriceWithVat?: number;
	@observable saleAmountWithVat?: number;
	@observable priceDifference?: number;
	@observable productUnit?: string;
	@observable convertedProductUnit?: string;
	@observable productTaxRate?: number;
	@observable convertedProductTaxRate?: number;

	@observable productMaxQuantity?: number;
	@observable convertedProductMaxQuantity?: number;

	@computed
	get amountDifference() {
		return round(
			evaluate('priceDifference * quantity', {
				priceDifference: bignumber(this.priceDifference),
				quantity: bignumber(this.quantity),
			}).toNumber(),
			4
		);
	}

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}
}

class Conversion extends Entity {
	@observable number?: string;

	@observable stockTurnoverItem?: StockTurnover;

	@observable isConfirming = false;

	@DayjsTransformer
	@observable
	date?: Dayjs;

	@ArrayTypeTransformer(ConversionItem)
	@observable
	items?: ConversionItem[] = [];

	type?: ConversionType;

	@flow.bound
	*confirm() {
		this.isConfirming = true;
		try {
			yield this.getClient().patch(`/conversions/${this.id}/confirm`);
		} catch (e) {
			//
		} finally {
			this.isConfirming = false;
		}
	}

	@flow.bound
	*unconfirm() {
		this.isConfirming = true;
		try {
			yield this.getClient().patch(`/conversions/${this.id}/unconfirm`);
		} catch (e) {
			throw e;
		} finally {
			this.isConfirming = false;
		}
	}

	get isDeletable() {
		return this.stockTurnoverItem?.status === StockTurnoverStatus.DRAFT;
	}

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}
}

class Conversions extends Store<Conversion> {
	@observable isFetchingQuantityAndPrice = false;

	constructor() {
		super(Conversion);
	}

	@flow.bound
	*fetchQuantityAndPrice(productId: string, warehouseId: string) {
		this.isFetchingQuantityAndPrice = true;
		try {
			const { data } = yield this.getClient().get(
				`/products/${productId}/quantity/${warehouseId}`
			);

			return data;
		} catch (e) {
			return null;
		} finally {
			this.isFetchingQuantityAndPrice = false;
		}
	}

	get isCreatable() {
		return true;
	}
}

export { Conversions, Conversion };
