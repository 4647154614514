import type { EditableFormInstance } from '@ant-design/pro-table/es';
import { t } from '@lingui/macro';
import { Divider, Input, InputNumber, Tag, Typography } from 'antd';
import { round } from 'lodash';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import styles from './ConversionItems.module.less';
import ProductInput from '../../../../../../components/Inputs/ProductInput';
import WarehouseSelect from '../../../../../../components/Selects/WarehouseSelect';
import { TableInputProTable } from '../../../../../../components/TableInputProTable';
import numberFormat from '../../../../../../lib/numberFormat';
import stores from '../../../../../../stores/index.mobx';
import { ConversionItem } from '../../../../../../stores/Conversion.mobx';
import { bignumber, evaluate } from 'mathjs';
type Props = {
	value?: ConversionItem[];
};

function ConversionsItemsInput({ value, ...props }: Props) {
	const {
		conversions: { fetchQuantityAndPrice, isFetchingQuantityAndPrice },
		warehouses: { byId: storesById, available: availableWarehouses },
		products: { byId: productsById },
	} = stores;

	const editableFormRef = useRef<EditableFormInstance>();
	const [initialValue, setInitialValue] = useState<Partial<ConversionItem>>({});

	useEffect(() => {
		if (availableWarehouses?.length === 1) {
			setInitialValue({
				warehouseId: availableWarehouses[0]?.id,
			});
		}
	}, [availableWarehouses]);

	const onSelectWarehouse = useCallback((warehouseId) => {
		setInitialValue({
			warehouseId,
		});
	}, []);

	const columns = useMemo(() => {
		return [
			{
				title: t`Складиште`,
				width: 150,
				dataIndex: 'warehouseId',
				formItemProps: {
					rules: [
						{
							required: true,
							message: t`Складиште је обавезно`,
						},
					],
				},
				renderFormItem: (
					{ handleKeyDown },
					{ recordKey, isEditable, record }
				) => {
					return isEditable ? (
						<WarehouseSelect
							popupMatchSelectWidth={false}
							onKeyDown={(e) => handleKeyDown(e, recordKey)}
							onSelect={onSelectWarehouse}
							onChange={(value) => {
								setTimeout(async () => {
									const product = productsById[record.productId];
									if (!product) {
										return;
									}

									let quantity = 0;
									let purchasePrice = 0;
									let oldSalePriceWithVat = 0;
									let productMaxQuantity = 0;

									if (value) {
										const quantityAndPrice = await flowResult(
											fetchQuantityAndPrice(product.id, value)
										);

										if (quantityAndPrice) {
											quantity = quantityAndPrice.quantity || 0;
											productMaxQuantity = quantityAndPrice.quantity || 0;
											purchasePrice =
												quantityAndPrice.averagePurchasePrice || 0;
											oldSalePriceWithVat =
												quantityAndPrice.salePriceWithVat || 0;
										}
									}

									const salePriceWithVat = record.salePriceWithVat || 0;
									const convertedProductQuantity = round(
										record.convertedProductQuantity || 0,
										3
									);
									const priceDifference = round(
										evaluate('salePriceWithVat - oldSalePriceWithVat', {
											salePriceWithVat: bignumber(salePriceWithVat),
											oldSalePriceWithVat: bignumber(oldSalePriceWithVat),
										}).toNumber(),
										2
									);

									const amountDifference = round(
										evaluate(
											'salePriceWithVat * convertedProductQuantity - oldSalePriceWithVat * quantity',
											{
												salePriceWithVat: bignumber(salePriceWithVat),
												oldSalePriceWithVat: bignumber(oldSalePriceWithVat),
												quantity: bignumber(quantity),
												convertedProductQuantity: bignumber(
													convertedProductQuantity
												),
											}
										).toNumber(),
										2
									);

									const productUnit = product?.unit?.name || `kom`;

									editableFormRef.current?.setRowData?.(recordKey, {
										oldSalePriceWithVat,
										productUnit,
										quantity,
										productMaxQuantity,
										purchasePrice,
										priceDifference,
										amountDifference,
									});
								}, 100);
							}}
						/>
					) : (
						<Input />
					);
				},
				render: (value, record, index) => {
					return storesById[value]?.name;
				},
			},
			{
				dataIndex: 'productMaxQuantity',
				editable: true,
				renderFormItem: () => <Input disabled />,
				hideInTable: true,
			},
			{
				title: t`Из артикла`,
				dataIndex: 'productId',
				formItemProps: {
					rules: [
						{
							required: true,
							message: t`Артикал је обавезан`,
						},
					],
				},
				renderFormItem: (
					{ handleKeyDown },
					{ isEditable, record, recordKey }
				) => {
					return isEditable ? (
						<ProductInput
							onKeyDown={(e) => handleKeyDown(e, recordKey)}
							onChange={(value) => {
								setTimeout(async () => {
									const product = productsById[value];
									if (!product) {
										return;
									}

									let quantity = 0;
									let purchasePrice = 0;
									let oldSalePriceWithVat = 0;
									let productMaxQuantity = 0;

									if (record.warehouseId) {
										const quantityAndPrice = await flowResult(
											fetchQuantityAndPrice(product.id, record.warehouseId)
										);

										if (quantityAndPrice) {
											quantity = quantityAndPrice.quantity || 0;
											productMaxQuantity = quantityAndPrice.quantity || 0;
											purchasePrice =
												quantityAndPrice.averagePurchasePrice || 0;
											oldSalePriceWithVat =
												quantityAndPrice.salePriceWithVat || 0;
										}
									}

									const productUnit = product?.unit?.name || `kom`;
									const salePriceWithVat = record.salePriceWithVat || 0;
									const convertedProductQuantity = round(
										record.convertedProductQuantity || 0,
										3
									);
									const priceDifference = round(
										evaluate('salePriceWithVat - oldSalePriceWithVat', {
											salePriceWithVat: bignumber(salePriceWithVat),
											oldSalePriceWithVat: bignumber(oldSalePriceWithVat),
										}).toNumber(),
										2
									);

									const amountDifference = round(
										evaluate(
											'salePriceWithVat * convertedProductQuantity - oldSalePriceWithVat * quantity',
											{
												salePriceWithVat: bignumber(salePriceWithVat),
												oldSalePriceWithVat: bignumber(oldSalePriceWithVat),
												quantity: bignumber(quantity),
												convertedProductQuantity: bignumber(
													convertedProductQuantity
												),
											}
										).toNumber(),
										2
									);

									editableFormRef.current?.setRowData?.(recordKey, {
										oldSalePriceWithVat,
										productUnit,
										quantity,
										productMaxQuantity,
										purchasePrice,
										priceDifference,
										amountDifference,
									});
								}, 100);
							}}
						/>
					) : (
						<Input />
					);
				},
				render: (value, record, index) => {
					const product = productsById[value];
					return product?.parent ? (
						<>
							<Typography.Text type="secondary">{product.sku}</Typography.Text>
							<Divider type="vertical" />
							{product.parent.name}&nbsp;&nbsp;
							<Tag color="magenta" bordered={false}>
								{product.variantName}
							</Tag>
						</>
					) : (
						<>
							<Typography.Text type="secondary">{product?.sku}</Typography.Text>
							<Divider type="vertical" />
							{product?.name}
						</>
					);
				},
			},
			{
				title: t`Количина`,
				dataIndex: 'quantity',
				width: 130,
				align: 'right',
				formItemProps: (_, config) => {
					return {
						initialValue: 0,
						rules: [
							{
								required: true,
								message: t`Количина је обавезна`,
							},
							({ getFieldValue }) => ({
								type: 'number',
								validator: async (_, value) => {
									const maxQuantity = getFieldValue([
										config.entity.id,
										'productMaxQuantity',
									]);
									if (value > maxQuantity) {
										return Promise.reject(
											t`Количина мора бити мања или једнака ${maxQuantity}`
										);
									}

									if (value > 0) {
										return Promise.resolve();
									}

									return Promise.reject(t`Количина мора бити већа од 0`);
								},
							}),
						],
					};
				},
				renderFormItem: (
					{ handlePressEnter, handleKeyDown },
					{ isEditable, record, recordKey }
				) => {
					return (
						<InputNumber
							type="number"
							controls={false}
							style={{ width: '100%' }}
							suffix={record.productUnit}
							onPressEnter={(e) => handlePressEnter(e, recordKey)}
							onKeyDown={(e) => handleKeyDown(e, recordKey)}
							onChange={(value) => {
								setTimeout(async () => {
									const quantity = Number(value);
									const oldSalePriceWithVat = record.oldSalePriceWithVat || 0;
									const salePriceWithVat = record.salePriceWithVat || 0;
									const convertedProductQuantity = round(
										record.convertedProductQuantity || 0,
										3
									);
									const priceDifference = round(
										evaluate('salePriceWithVat - oldSalePriceWithVat', {
											salePriceWithVat: bignumber(salePriceWithVat),
											oldSalePriceWithVat: bignumber(oldSalePriceWithVat),
										}).toNumber(),
										2
									);

									const amountDifference = round(
										evaluate(
											'salePriceWithVat * convertedProductQuantity - oldSalePriceWithVat * quantity',
											{
												salePriceWithVat: bignumber(salePriceWithVat),
												oldSalePriceWithVat: bignumber(oldSalePriceWithVat),
												quantity: bignumber(quantity),
												convertedProductQuantity: bignumber(
													convertedProductQuantity
												),
											}
										).toNumber(),
										2
									);

									editableFormRef.current?.setRowData?.(recordKey, {
										quantity: value,
										priceDifference,
										amountDifference,
									});
								}, 100);
							}}
						/>
					);
				},
				render: (value: string, record) => {
					return `${numberFormat(value, false, 3, false)} ${
						record.productUnit
					}`;
				},
			},
			{
				width: 32,
				renderFormItem: () => (
					<i className={`fi fi-rr-arrow-right ${styles.arrow}`} />
				),
				render: () => <i className={`fi fi-rr-arrow-right ${styles.arrow}`} />,
			},
			{
				title: t`У артикал`,
				dataIndex: 'convertedProductId',
				formItemProps: {
					rules: [
						{
							required: true,
							message: t`Артикал је обавезан`,
						},
					],
				},
				renderFormItem: (
					{ handleKeyDown },
					{ isEditable, record, recordKey }
				) => {
					return isEditable ? (
						<ProductInput
							onKeyDown={(e) => handleKeyDown(e, recordKey)}
							onChange={(value) => {
								setTimeout(async () => {
									const product = productsById[value];
									if (!product) {
										return;
									}

									const oldSalePriceWithVat = record.oldSalePriceWithVat || 0;
									const quantity = record.quantity || 0;

									const purchasePrice = 0;
									let salePriceWithVat = 0;

									if (record.warehouseId) {
										const quantityAndPrice = await flowResult(
											fetchQuantityAndPrice(product.id, record.warehouseId)
										);

										if (quantityAndPrice) {
											salePriceWithVat = quantityAndPrice.salePriceWithVat || 0;
										}
									}

									const convertedProductUnit = product?.unit?.name || `kom`;

									const convertedProductQuantity = round(
										record.convertedProductQuantity || 0,
										3
									);
									const priceDifference = round(
										evaluate('salePriceWithVat - oldSalePriceWithVat', {
											salePriceWithVat: bignumber(salePriceWithVat),
											oldSalePriceWithVat: bignumber(oldSalePriceWithVat),
										}).toNumber(),
										2
									);

									const amountDifference = round(
										evaluate(
											'salePriceWithVat * convertedProductQuantity - oldSalePriceWithVat * quantity',
											{
												salePriceWithVat: bignumber(salePriceWithVat),
												oldSalePriceWithVat: bignumber(oldSalePriceWithVat),
												quantity: bignumber(quantity),
												convertedProductQuantity: bignumber(
													convertedProductQuantity
												),
											}
										).toNumber(),
										2
									);

									editableFormRef.current?.setRowData?.(recordKey, {
										salePriceWithVat,
										priceDifference,
										amountDifference,
										convertedProductUnit,
									});
								}, 100);
							}}
						/>
					) : (
						<Input />
					);
				},
				render: (value, record, index) => {
					const product = productsById[value];
					return product?.parent ? (
						<>
							<Typography.Text type="secondary">{product.sku}</Typography.Text>
							<Divider type="vertical" />
							{product.parent.name}&nbsp;&nbsp;
							<Tag color="magenta" bordered={false}>
								{product.variantName}
							</Tag>
						</>
					) : (
						<>
							<Typography.Text type="secondary">{product?.sku}</Typography.Text>
							<Divider type="vertical" />
							{product?.name}
						</>
					);
				},
			},
			{
				title: t`Количина`,
				dataIndex: 'convertedProductQuantity',
				width: 130,
				align: 'right',
				formItemProps: {
					initialValue: 0,
					rules: [
						{
							required: true,
							message: t`Количина је обавезна`,
						},
						{
							type: 'number',
							validator: async (_, value) => {
								if (value > 0) {
									return Promise.resolve();
								}

								return Promise.reject(t`Количина мора бити већа од 0`);
							},
						},
					],
				},
				renderFormItem: (
					{ handlePressEnter, handleKeyDown },
					{ isEditable, record, recordKey }
				) => {
					return (
						<InputNumber
							type="number"
							controls={false}
							style={{ width: '100%' }}
							suffix={record.convertedProductUnit}
							onPressEnter={(e) => handlePressEnter(e, recordKey)}
							onKeyDown={(e) => handleKeyDown(e, recordKey)}
							onChange={(value) => {
								setTimeout(async () => {
									const convertedProductQuantity = Number(value);
									const oldSalePriceWithVat = record.oldSalePriceWithVat || 0;
									const salePriceWithVat = record.salePriceWithVat || 0;
									const quantity = round(record.quantity || 0, 3);
									const priceDifference = round(
										evaluate('salePriceWithVat - oldSalePriceWithVat', {
											salePriceWithVat: bignumber(salePriceWithVat),
											oldSalePriceWithVat: bignumber(oldSalePriceWithVat),
										}).toNumber(),
										2
									);

									const amountDifference = round(
										evaluate(
											'salePriceWithVat * convertedProductQuantity - oldSalePriceWithVat * quantity',
											{
												salePriceWithVat: bignumber(salePriceWithVat),
												oldSalePriceWithVat: bignumber(oldSalePriceWithVat),
												quantity: bignumber(quantity),
												convertedProductQuantity: bignumber(
													convertedProductQuantity
												),
											}
										).toNumber(),
										2
									);

									editableFormRef.current?.setRowData?.(recordKey, {
										quantity,
										priceDifference,
										amountDifference,
									});
								}, 100);
							}}
						/>
					);
				},
				render: (value: string, record) => {
					return `${numberFormat(value, false, 3, false)} ${
						record.productUnit
					}`;
				},
			},
			{
				title: t`Набавна цена`,
				dataIndex: 'purchasePrice',
				width: 120,
				align: 'right',
				formItemProps: {
					initialValue: 0,
				},
				renderFormItem: () => {
					return <Input disabled />;
				},
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},
			{
				title: t`Стара цена`,
				dataIndex: 'oldSalePriceWithVat',
				width: 120,
				align: 'right',
				formItemProps: {
					initialValue: 0,
				},
				renderFormItem: () => {
					return <Input disabled />;
				},
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},

			{
				title: t`Нова цена`,
				dataIndex: 'salePriceWithVat',
				width: 120,
				align: 'right',
				formItemProps: {
					initialValue: 0,
				},
				renderFormItem: (
					{ handlePressEnter, handleKeyDown },
					{ isEditable, record, recordKey }
				) => {
					return isEditable ? (
						<InputNumber
							disabled
							type="number"
							controls={false}
							style={{ width: '100%' }}
							className={styles.rightAlignNumberInput}
						/>
					) : (
						<Input />
					);
				},
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},
			{
				title: t`Разлика у цени`,
				dataIndex: 'priceDifference',
				width: 130,
				align: 'right',
				formItemProps: {
					initialValue: 0,
				},
				renderFormItem: () => (
					<InputNumber
						disabled
						controls={false}
						className={styles.rightAlignNumberInput}
						style={{ width: '100%' }}
						onFocus={(event) => {
							event.currentTarget.select();
						}}
					/>
				),
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},
			{
				title: t`Раз. прод. вред.`,
				dataIndex: 'amountDifference',
				width: 130,
				align: 'right',
				formItemProps: {
					initialValue: 0,
				},
				renderFormItem: () => (
					<InputNumber
						disabled
						controls={false}
						className={styles.rightAlignNumberInput}
						style={{ width: '100%' }}
					/>
				),
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},
		];
	}, [fetchQuantityAndPrice, onSelectWarehouse, productsById, storesById]);

	return (
		<>
			<TableInputProTable<ConversionItem>
				editableFormRef={editableFormRef}
				// shouldShowDuplicateButton
				shouldShowIndexColumn
				addButtonText={t`Додај артикал`}
				emptyText={t`Нема ставки`}
				iconPath="/images/icons/new/box.svg"
				columns={columns}
				value={value}
				initialValue={initialValue}
				tableProps={{
					loading: isFetchingQuantityAndPrice,
					scroll: {
						x: 1900,
					},
				}}
				{...props}
			/>
		</>
	);
}

export default observer(ConversionsItemsInput);
