import Bugsnag from '@bugsnag/browser';
import { t } from '@lingui/macro';
import { Progress } from 'antd';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import html2pdf from 'html2pdf.js/src';
import reverse from 'lodash/reverse';
import round from 'lodash/round';
import { action, computed, flow, observable } from 'mobx';
import printjs from 'print-js';
import Deferred from 'promise-deferred';
import { createRoot } from 'react-dom/client';

import { CreateStore } from './Crud.mobx';
import stores from './index.mobx';
import { Customer, LocalSale, LocalSales } from './LocalSale.mobx';
import { Product, Products } from './Product.mobx';
import { InvoiceData } from './SDC.mobx';
import { Store } from './Store.mobx';
import { ArrayTypeTransformer } from './transformers/ArrayType';
import { DayjsTransformer } from './transformers/Dayjs';
import { ReferenceTransformer } from './transformers/Reference';
import { User } from './User.mobx';
import enUS from '../assets/locales/journal/en-US.json';
import srCyrlRS from '../assets/locales/journal/sr-Cyrl-RS.json';
import srLatnRS from '../assets/locales/journal/sr-Latn-RS.json';
import { StaticComponents } from '../components/StaticComponents';
import { POS_NO } from '../constants/application';
import { ERROR_CONFLICT_DUPLICATE_RECEIPT } from '../constants/errors';
import { TAX_FREE_LABEL } from '../constants/invoice';
import {
	INVOICE_TYPE_MAP,
	PAYMENT_TYPE_MAP,
	PaymentType,
	InvoiceType,
	TRANSACTION_TYPE_MAP,
	TransactionType,
	InvoiceTypeAPI,
	PaymentTypeAPI,
	TransactionTypeAPI,
	INVOICE_TYPE_FROM_STRING,
	PAYMENT_TYPE_FROM_STRING,
	ADVANCE_TYPE,
} from '../constants/journal';
import { A4Journal } from '../lib/a4Journal';
import numberFormat from '../lib/numberFormat';
import { SocketError } from '../lib/socketError';
import { ThermalJournal } from '../lib/thermalJournal';
import { ThermalPosTerminal } from '../lib/thermalPosTerminal';
import { PosTerminalDeviceConfiguration } from './Device.mobx';
import { bignumber, evaluate } from 'mathjs';

const locales = {
	'en-US': enUS,
	'sr-Cyrl-RS': srCyrlRS,
	'sr-Latn-RS': srLatnRS,
};

let deferred = null;
let deferredCreateReceipt = null;

const { Store: CrudStore, Entity } = CreateStore({
	name: 'receipt',
	paginated: true,
	persistFields: ['failedSyncing', 'syncQueue'],
	persistDelay: 0,
});

export interface DraftReceiptPayment {
	paymentType: PaymentTypeAPI;
	amount: number;
}

export interface DraftReceiptItem {
	sku: number;
	quantity: number;
	unitPrice?: number;
}

export interface DraftReceiptDelivery {
	thermalPrinter?: boolean;
	a4Printer?: boolean;
	email?: string;
	pdf?: boolean;
	html?: boolean;
}

export interface CreateInvoiceOutput {
	thermal?: boolean;
	a4?: boolean;
	email?: string | null;
	pdf?: boolean;
	base64pdf?: string;
	html?: boolean;
}

export interface CreateDraftReceiptRequest {
	invoiceType: InvoiceTypeAPI;
	dateAndTimeOfIssue?: string;
	items: DraftReceiptItem[];
}

export interface FinalizeDraftReceiptRequest {
	payment?: DraftReceiptPayment[];
	receiptDelivery?: DraftReceiptDelivery;
	buyerId?: string;
	buyerCostCenterId?: string;
}

export interface FiscalizeNormalRequest {
	request: CreateInvoiceRequest;
	includeSignature: boolean;
	receiptDelivery: CreateInvoiceOutput;
	lastAdvanceSale?: Partial<Receipt>;
}

export type ReceiptPayment = {
	paymentType: PaymentTypeAPI;
	amount: number;
};

export type SyncQueue = {
	url: string;
	data: any;
};

export type TaxItem = {
	label: string;
	categoryName: string;
	rate: number;
	amount: number;
};

export type SecureElement = {
	uid: string;
	tin: string;
	businessName: string;
	locationName: string;
	address: string;
	district: string;
};

export type AdvanceData = {
	advancePayments: number;
};

export function convertPayments(
	payments: ReceiptPayment[]
): Record<string, number> {
	return payments.reduce((acc, cur) => {
		return {
			...acc,
			[cur.paymentType]: cur.amount,
		};
	}, {});
}

export type CreateInvoiceRequest = {
	invoiceType: InvoiceType;
	transactionType: TransactionType;
	buyerId?: string | null;
	buyerCostCenterId?: string | null;
	posTime?: Dayjs;
	referentDocumentNumber?: string;
	referentDocumentDT?: Dayjs;
	payments?: {
		cash?: number;
		check?: number;
		card?: number;
		wiretransfer?: number;
		voucher?: number;
		mobilemoney?: number;
		other?: number;
	};
	change?: number;
	items: {
		product: Partial<Product>;
		variant?: Partial<Product>;
		quantity: number;
		taxRateLabel?: string;
		finalPrice: number;
		discount?: number;
		isAdvance?: boolean;
	}[];
	advanceItems?: {
		product: Partial<Product>;
		variant?: Partial<Product>;
		quantity: number;
		taxRateLabel?: string;
		finalPrice: number;
		discount?: number;
	}[];
	unknownAmountAdvance?: boolean;
	additionalText?: string;
	advancePayments?: number;
};

let retryInterval = null;

export class ReceiptItem extends Entity {
	@observable productId?: string;
	@observable name?: string;
	@observable unitPrice?: number;
	@observable totalAmount?: number;
	@observable quantity?: number;
	@observable taxLabels?: string[];
	@observable unit?: string;
	@observable sku?: number;
	@observable isPieceUnitOfMeasure?: boolean;
	@observable gtin?: string;
	@observable discount?: number;

	@computed
	get taxRateLabel() {
		return this.taxLabels?.[0];
	}

	@ReferenceTransformer('product', 'productId')
	product?: Product;

	@observable receipt?: Receipt;

	constructor(data, parent) {
		super(parent);
		this.init(data);
		if (data.receipt) {
			this.receipt = new Receipt(data.receipt, this);
		}
	}
}

class Receipt extends Entity {
	@observable totalAmount?: number;
	@observable payment?: ReceiptPayment[] = [];
	@observable invoiceType?: InvoiceTypeAPI;
	@observable transactionType?: TransactionTypeAPI;
	@observable invoiceNumber?: string;
	@observable buyerId?: string;
	@observable buyerCostCenterId?: string;
	@observable posNumber?: string;
	@observable referentDocumentNumber?: string;
	@observable verificationUrl?: string;
	@observable taxItems?: TaxItem[] = [];
	@observable storeId?: string;
	@observable cashierId?: string;
	@observable secureElement?: SecureElement;
	@observable taxTotal?: number;
	@observable paymentChange?: number;
	@observable paymentTotal?: number;
	@observable invoiceCounter?: string;
	@observable advanceData?: AdvanceData;
	@observable void? = false;
	@observable voids? = false;
	@observable sdcRequest?: any;
	@observable sdcResponse?: any;
	@observable additionalText?: string;
	@observable unknownAmountAdvance?: boolean = false;
	@observable posTerminalResponse?: {
		Identifier: number;
		TerminalID: number;
		SourceID: number;
		SequentialNumber: number;
		TransactionNumber: number;
		BatchNumber: number;
		AmountCurrency: number;
		TransactionAmount: number;
		AmountExponent: number;
		TIDNumber: number;
		MIDNumber: number;
		PINBlock: number;
		TransactionAmountCash: number;
		DCCNumber: number;
		DCCAmount: number;
		TransactionType: string;
		TransactionFlag: string;
		TransactionDate: string;
		TransactionTime: string;
		CardDataSource: string;
		CardNumber: string;
		ExpirationDate: string;
		AuthorizationCode: string;
		CompanyName: string;
		DisplayMessage: string;
		InputData: string;
		EMVData: {
			'84': string;
			'95': string;
			'9F12': string;
			'9F26': string;
			'9F27': string;
			'9F34': string;
		};
		rawEMVData: string;
		AckquirerName: string;
		DebitTransactionCount: string;
		DebitTransactionAmount: string;
		RefundTransactionCount: string;
		RefundTransactionAmount: string;
		InstallmentsNumber: string;
		FullResponseCode: string;
		TransactionStatus: string;
		SPDHTerminalTotals: string;
		SPDHHostTotals: string;
		CardholderName: string;
		RRN: string;
		PayservicesData: string;
		AvailableBalance: string;
		OfflineCryptogram: string;
		LoyaltyData: string;
		FormattedTTP: string;
		DCCProvider: string;
		DCCExchangeRate: string;
		DCCExchangeRateDate: string;
		DCCMarkUpPercent: string;
		DCCDisclaimer: string;
		DCCStatus: string;
		DCCCurencySymbol: string;
		InstantPaymentReference: string;
		PersonalVehicleCardData: string;
		SignatureLinePrintFlag: boolean;
		MoreMessagesFlag: boolean;
		PINFlag: boolean;
		MifareCardType: string;
		MifareCardUID: string;
		F4GCode: string;
		RadcomTransportationSpecificData: string;
		AcqirerID: string;
		rawData: string;
	};

	@ReferenceTransformer('store', 'storeId')
	store?: Store;
	cashier?: User;

	@DayjsTransformer
	posTime?: Dayjs;
	@DayjsTransformer
	referentDocumentDT?: Dayjs;
	@DayjsTransformer
	sdcTime?: Dayjs;

	@ArrayTypeTransformer(Receipt)
	@observable
	connectedReceipts?: Receipt[];

	@ArrayTypeTransformer(ReceiptItem)
	@observable
	receiptItems: ReceiptItem[] = [];

	@ArrayTypeTransformer(ReceiptItem)
	@observable
	advanceItems?: ReceiptItem[] = [];

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	replace(data: any): void {
		super.replace(data);
		this.cashier = new User(data.cashier, this);
	}

	get isEditable() {
		return false;
	}

	get isDeletable() {
		return false;
	}

	@computed
	get hasSignatureLine() {
		return (
			this.invoiceType === INVOICE_TYPE_MAP[InvoiceType.COPY] &&
			this.transactionType === TRANSACTION_TYPE_MAP[TransactionType.REFUND] &&
			this.payment.some(
				(payment) => payment.paymentType === PAYMENT_TYPE_MAP[PaymentType.CASH]
			) &&
			!this.connectedReceipts.find(
				(receipt) => receipt.invoiceNumber === this.referentDocumentNumber
			)?.voids
		);
	}
	@computed
	get showAdvanceSum() {
		return (
			this.invoiceType === INVOICE_TYPE_MAP[InvoiceType.NORMAL] &&
			this.transactionType === TRANSACTION_TYPE_MAP[TransactionType.SALE] &&
			this.connectedReceipts?.some(
				(receipt) =>
					receipt.invoiceType === INVOICE_TYPE_MAP[InvoiceType.ADVANCE]
			)
		);
	}

	@computed
	get advancePaymentsSum() {
		return this.connectedReceipts
			?.filter(
				(receipt) =>
					receipt.invoiceType === INVOICE_TYPE_MAP[InvoiceType.ADVANCE] &&
					dayjs(receipt.sdcTime).isBefore(this.sdcTime) &&
					receipt.invoiceNumber !== this.referentDocumentNumber
			)
			.reduce((acc, cur) => {
				const paymentTotal =
					cur.transactionType === TRANSACTION_TYPE_MAP[TransactionType.SALE]
						? cur.paymentTotal
						: -cur.paymentTotal;
				return acc + paymentTotal - cur.paymentChange;
			}, 0);
	}

	@computed
	get lastAdvance() {
		const lastAdvance = reverse([...this.connectedReceipts])
			?.filter(
				(receipt) =>
					receipt.invoiceType === INVOICE_TYPE_MAP[InvoiceType.ADVANCE] &&
					dayjs(receipt.sdcTime).isBefore(this.sdcTime)
			)
			.find(
				(receipt) =>
					receipt.transactionType ===
						TRANSACTION_TYPE_MAP[TransactionType.SALE] &&
					receipt.invoiceType === INVOICE_TYPE_MAP[InvoiceType.ADVANCE]
			);

		const closed = reverse([...this.connectedReceipts])?.find(
			(receipt) =>
				receipt.transactionType ===
					TRANSACTION_TYPE_MAP[TransactionType.SALE] &&
				receipt.invoiceType === INVOICE_TYPE_MAP[InvoiceType.NORMAL]
		);

		if (
			closed &&
			!closed.void &&
			this.invoiceType === INVOICE_TYPE_MAP[InvoiceType.NORMAL] &&
			this.transactionType === TRANSACTION_TYPE_MAP[TransactionType.SALE]
		) {
			return lastAdvance;
		}
	}
}

class Receipts extends CrudStore<Receipt> {
	@observable failedSyncing: SyncQueue[] = [];
	@observable syncQueue: SyncQueue[] = [];

	constructor() {
		super(Receipt);
	}

	get isCreatable() {
		return false;
	}

	@action.bound
	addToFailedQueue(url: string, data: any) {
		this.failedSyncing.push({ url, data });
	}

	@action.bound
	addToSyncQueue(url: string, data: any) {
		const item = { url, data };
		this.syncQueue.push(item);
		return item;
	}

	@action.bound
	removeFailed(failedReceipt) {
		this.failedSyncing = this.failedSyncing.filter(
			(failed) => failed !== failedReceipt
		);
	}

	@action.bound
	removeSyncQueue(syncedReceipt) {
		this.syncQueue = this.syncQueue.filter(
			(synced) => synced !== syncedReceipt
		);
	}

	@flow.bound
	*getByInvoiceNumber(invoiceNumber: string) {
		const response = yield this.getClient().get(
			`/receipts/by-invoice-number/${invoiceNumber}`
		);
		return new Receipt(response.data, this);
	}

	@flow.bound
	*getLastReceipt(storeId: string) {
		const response = yield this.getClient().get(`/receipts/last/${storeId}`);
		return new Receipt(response.data, this);
	}

	@flow.bound
	*importReceipts(data: any) {
		yield this.getClient().post(`/receipts/import`, data);
	}

	@flow.bound
	*retryFailed() {
		clearInterval(retryInterval);
		retryInterval = null;
		const count = this.failedSyncing.length;
		let current = 0;
		let errorCount = 0;
		StaticComponents.notification.info({
			key: 'receiptSync',
			message: t`Синхронизација рачуна`,
			description: (
				<span>
					<Progress percent={0} status="active" />
				</span>
			),
			duration: 0,
		});
		yield this.failedSyncing.reduce(async (previousPromise, receipt) => {
			await previousPromise;
			try {
				const { url, data } = receipt;
				await this.getClient().post(url, data);

				this.removeFailed(receipt);
			} catch (apiError) {
				if (
					apiError.response?.data?.errorCode ===
					ERROR_CONFLICT_DUPLICATE_RECEIPT
				) {
					this.removeFailed(receipt);
				} else {
					errorCount += 1;
				}
			}
			current += 1;
			StaticComponents.notification.info({
				key: 'receiptSync',
				message: t`Синхронизација рачуна`,
				description: (
					<span>
						<Progress percent={(current / count) * 100} status="active" />
					</span>
				),
				duration: 0,
			});
		}, Promise.resolve());

		if (this.failedSyncing.length === 0) {
			StaticComponents.notification.success({
				key: 'receiptSync',
				message: t`Синхронизација успешна`,
				description: t`Рачуни су успешно синхронозовани са сервером.`,
				duration: 10,
			});
		} else {
			retryInterval = setInterval(() => {
				this.retryFailed();
			}, 60000);

			if (errorCount > 0) {
				StaticComponents.notification.warning({
					key: 'receiptSync',
					message: t`Синхронизација неуспешна`,
					description: t`Синхронизација није потпуно завршена. Број грешака: ${errorCount} `,
					duration: 0,
				});
			}
		}
	}

	@flow.bound
	async *saveWithFailHandler(url: string, data: any) {
		const clonedData = JSON.parse(JSON.stringify(data));
		const item = this.addToSyncQueue(url, clonedData);

		try {
			while (deferred) {
				await deferred.promise;
			}

			deferred = new Deferred();
			yield this.getClient()
				.post(url, data)
				.then(() => {
					deferred.resolve();
					deferred = null;
				});
			if (window.location.pathname === '/app/receipts/list') {
				if (this.single) {
					this.fetchSingle(this.single.id);
				}
				this.fetchAll(
					this.pagination?.limit,
					this.pagination?.offset,
					this.pagination?.filters
				);
			}
		} catch (e) {
			if (e.response?.data?.errorCode !== ERROR_CONFLICT_DUPLICATE_RECEIPT) {
				// Stringify and parse to force conversion to plain object
				// Fixes a problem with sending malformed dates to the server
				this.addToFailedQueue(url, clonedData);
				this.removeSyncQueue(item);
			}

			if (this.failedSyncing.length > 0) {
				if (!retryInterval) {
					retryInterval = setInterval(() => {
						this.retryFailed();
					}, 60000);
				}
			}

			if (deferred) {
				deferred.resolve();
				deferred = null;
			}
		}
	}

	@flow.bound
	*sendEmail(email: string, data: string, invoiceNumber: string) {
		yield this.saveWithFailHandler(`/receipts/send-email`, {
			email,
			data,
			invoiceNumber,
			verificationUrls: [],
		});
	}
	@flow.bound
	*download(data: string, invoiceNumber: string) {
		const response = yield this.getClient().post(`/receipts/download`, {
			data,
			invoiceNumber,
		});
		if (window.electron) {
			const element = document.createElement('a');

			element.href = response.data.url;
			element.download = `${invoiceNumber}.pdf`;
			element.click();
		} else {
			window.open(response.data.url, '_blank');
		}
	}

	@flow.bound
	*createInvoice(
		invoiceType: InvoiceType,
		transactionType: TransactionType,
		payment: Record<string, number | string | Dayjs | void>,
		output: {
			thermal?: boolean;
			a4?: boolean;
			email?: string | null;
			pdf?: boolean;
			html?: boolean;
			base64pdf?: boolean;
		},
		items: any[],
		includeSignature = false,
		advanceItems = [],
		lastAdvanceSale?: any
	) {
		const sdc = stores.sdc;
		const user = stores.users.authenticatedUser;
		const requestPayment = [
			...(payment.cash > 0
				? [{ amount: payment.cash, paymentType: PaymentType.CASH }]
				: []),
			...(payment.card > 0
				? [{ amount: payment.card, paymentType: PaymentType.CARD }]
				: []),
			...(payment.check > 0
				? [{ amount: payment.check, paymentType: PaymentType.CHECK }]
				: []),
			...(payment.wiretransfer > 0
				? [
						{
							amount: payment.wiretransfer,
							paymentType: PaymentType.WIRE_TRANSFER,
						},
				  ]
				: []),
			...(payment.mobilemoney > 0
				? [
						{
							amount: payment.mobilemoney,
							paymentType: PaymentType.MOBILE_MONEY,
						},
				  ]
				: []),
			...(payment.voucher > 0
				? [{ amount: payment.voucher, paymentType: PaymentType.VOUCHER }]
				: []),
			...(payment.other > 0
				? [{ amount: payment.other, paymentType: PaymentType.OTHER }]
				: []),
		];
		const invoiceRequest = {
			invoiceType,
			transactionType,
			payment:
				requestPayment.length > 0
					? requestPayment
					: [
							{
								amount: 0,
								paymentType: PaymentType.CASH,
							},
					  ],
			paymentChange: payment.change || 0,
			cashier: user.fullName,
			invoiceNumber: POS_NO,
			buyerId: payment.buyerId ? payment.buyerId : undefined,
			buyerCostCenterId: payment.buyerCostCenterId
				? payment.buyerCostCenterId
				: undefined,
			referentDocumentNumber: payment.referentDocumentNumber,
			referentDocumentDT: payment.referentDocumentDT,
			dateAndTimeOfIssue: payment.posTime,
			items: items.map((item) => ({
				id: item.variant ? item.variant.id : item.product.id,
				gtin: (item.product.ean || '').trim() !== '' ? item.product.ean : null,
				name: `${item.product.name}${
					item.variant?.variantName ? ` ${item.variant?.variantName}` : ''
				}`,
				unit: item.variant
					? item.variant.saleUnit?.label
					: item.product?.saleUnit?.label || '',
				isPieceUnitOfMeasure: item.variant
					? item.variant.saleUnit?.isPieceUnitOfMeasure
					: item.product?.saleUnit?.isPieceUnitOfMeasure,
				sku: item.variant ? item.variant.sku : item.product.sku,
				quantity: round(item.quantity, 3),
				unitPrice: round(item.finalPrice, 2),
				labels: item.labels || [item.product.taxRateLabel],
				totalAmount: round(
					round(item.quantity, 3) * round(item.finalPrice, 2),
					2
				),
				discount: item.discount,
			})),
			options: {
				omitQRCodeGen: '0',
				omitTextualRepresentation: '0',
			},
		};
		let receiptData;
		let invoiceResponse;
		let saved = false;
		let sent = false;
		try {
			while (deferredCreateReceipt) {
				yield deferredCreateReceipt.promise;
			}
			deferredCreateReceipt = new Deferred();
			invoiceResponse = yield sdc.createInvoice(invoiceRequest);

			receiptData = {
				tin: invoiceResponse.tin,
				businessName: invoiceResponse.businessName,
				locationName: invoiceResponse.locationName,
				address: invoiceResponse.address,
				district: invoiceResponse.district,
				totalAmount: invoiceResponse.totalAmount,
				payment: invoiceRequest.payment.map((payment) => ({
					...payment,
					amount:
						payment.paymentType === PaymentType.CASH
							? Number(payment.amount) + Number(invoiceRequest.paymentChange)
							: Number(payment.amount),
					paymentType: PAYMENT_TYPE_MAP[payment.paymentType],
				})),
				paymentChange: Number(invoiceRequest.paymentChange),
				invoiceType: INVOICE_TYPE_MAP[invoiceRequest.invoiceType],
				transactionType: TRANSACTION_TYPE_MAP[invoiceRequest.transactionType],
				invoiceNumber: invoiceResponse.invoiceNumber,
				buyerId: invoiceRequest.buyerId,
				buyerCostCenterId: invoiceRequest.buyerCostCenterId,
				referentDocumentNumber: invoiceRequest.referentDocumentNumber,
				referentDocumentDT: invoiceRequest.referentDocumentDT,
				items: invoiceRequest.items,
				additionalText: payment.additionalText,
				unknownAmountAdvance: payment.unknownAmountAdvance,

				advanceItems: advanceItems
					? advanceItems.map((item) => ({
							id: item.variant ? item.variant.id : item.product.id,
							gtin:
								(item.product.ean || '').trim() !== ''
									? item.product.ean
									: null,
							name: `${item.product.name}${
								item.variant?.variantName ? ` ${item.variant?.variantName}` : ''
							}`,
							unit: item.variant
								? item.variant.saleUnit?.label
								: item.product?.saleUnit?.label || '',
							isPieceUnitOfMeasure: item.variant
								? item.variant.saleUnit?.isPieceUnitOfMeasure
								: item.product?.saleUnit?.isPieceUnitOfMeasure,
							sku: item.variant ? item.variant.sku : item.product.sku,
							quantity: round(item.quantity, 3),
							unitPrice: round(item.finalPrice, 2),
							labels: item.labels || [item.product.taxRateLabel],
							totalAmount: round(
								round(item.quantity, 3) * round(item.finalPrice, 2),
								2
							),
					  }))
					: null,
				posNumber: invoiceRequest.invoiceNumber,
				posTime: invoiceRequest.dateAndTimeOfIssue,
				sdcTime: invoiceResponse.sdcDateTime,
				verificationUrl: invoiceResponse.verificationUrl,
				taxItems: invoiceResponse.taxItems,
				invoiceCounter: invoiceResponse.invoiceCounter,
				storeId: stores.stores.currentStoreId,
				...(typeof payment.advancePayments !== 'undefined'
					? { advanceData: { advancePayments: payment.advancePayments } }
					: {}),
				sdcRequest: invoiceRequest,
				sdcResponse: invoiceResponse,
				// emails: output.email ? [output.email] : [],
				// receiptHTML: html,
			};

			setTimeout(() => {
				this.saveWithFailHandler(`/receipts`, receiptData);
			});
			saved = true;
			const a4Journal = new A4Journal(
				invoiceRequest,
				invoiceResponse,
				includeSignature,
				payment.advancePayments,
				advanceItems,
				lastAdvanceSale,
				payment.additionalText,
				payment.unknownAmountAdvance
			);

			const element = document.createElement('div');

			const root = createRoot(element);
			root.render(a4Journal.getComponent());

			yield a4Journal.getRenderDeferred();

			document.querySelector('#a4-temporary').innerHTML = element.innerHTML;
			const html = element.innerHTML;

			if (output.a4) {
				setTimeout(() => {
					printjs({
						printable: 'a4-temporary',
						type: 'html',
						targetStyles: ['*'],
						font_size: '',
					});
				});
			}

			if (output.thermal) {
				setTimeout(() => {
					const thermalJournal = new ThermalJournal(
						invoiceRequest,
						invoiceResponse,
						includeSignature,
						payment.advancePayments,
						advanceItems,
						lastAdvanceSale,
						payment.additionalText,
						payment.unknownAmountAdvance
					);
					thermalJournal.print();
				});
			}

			if (output.email) {
				setTimeout(() => {
					this.saveWithFailHandler(`/receipts/send-email`, {
						email: output.email,
						data: html,
						invoiceNumber: invoiceResponse.invoiceNumber,
						verificationUrls: [invoiceResponse.verificationUrl],
					});
				});
				sent = true;
			}

			const pdf =
				output.pdf || output.base64pdf
					? yield html2pdf()
							.set({
								margin: 1,
								filename: `${invoiceResponse.invoiceNumber}.pdf`,
								pageBreak: {
									mode: 'css',
									after: ['.page'],
								},
								html2canvas: { scale: 2 },
								jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait' },
							})
							.from(html)
							.toPdf()
							.output('datauristring')
					: null;

			deferredCreateReceipt.resolve();
			deferredCreateReceipt = null;
			StaticComponents.notification.success({
				message: t`Рачун ${invoiceResponse.invoiceNumber} је успешно издат`,
				description:
					payment.change && invoiceRequest.invoiceType !== InvoiceType.COPY
						? t`Извршите повраћај у износу од ${numberFormat(
								payment.change,
								true,
								2,
								true
						  )}`
						: '',
				duration: 10,
			});

			try {
				root.unmount();
			} catch (unmountError) {
				Bugsnag.notify(unmountError);
			}

			return {
				invoice: {
					...invoiceResponse,
					...(output.pdf ? { pdf } : {}),
					...(output.base64pdf ? { base64pdf: pdf.split(',')[1] } : {}),
					...(output.html ? { html: html } : {}),
				},
			};
		} catch (e) {
			while (deferredCreateReceipt) {
				deferredCreateReceipt.reject();
				deferredCreateReceipt = null;
			}
			if (invoiceResponse) {
				let message = '';
				let description = '';
				if ((output.a4 || output.thermal) && output.email) {
					message = t`Рачун ${invoiceResponse.invoiceNumber} је издат, али је дошло до грешке приликом штампања или слања путем електронске поште`;
					if (saved && sent) {
						description = t`Апликација ће покушати да сачува и поново пошаље рачун.`;
					} else if (saved && !sent) {
						description = t`Апликација ће покушати да сачува рачун, али ћете морати ручно да пошаљете рачун путем електронске поште.`;
					} else {
						description = t`Апликација због непредвиђене грешке не може сачувати рачун.`;
					}
				} else if (output.a4 || output.thermal) {
					message = t`Рачун ${invoiceResponse.invoiceNumber} је издат, али је дошло до грешке приликом штампања.`;
					if (saved) {
						description = t`Апликација ће покушати да сачува рачун.`;
					} else {
						description = t`Апликација због непредвиђене грешке не може сачувати рачун.`;
					}
				} else if (output.email) {
					message = t`Рачун ${invoiceResponse.invoiceNumber} је издат, али је дошло до грешке приликом слања путем електронске поште`;
					if (saved && sent) {
						description = t`Апликација ће покушати да сачува и поново пошаље рачун.`;
					} else if (saved && !sent) {
						description = t`Апликација ће покушати да сачува рачун, али ћете морати ручно да пошаљете рачун путем електронске поште.`;
					} else {
						description = t`Апликација због непредвиђене грешке не може сачувати рачун.`;
					}
				} else {
					message = t`Рачун ${invoiceResponse.invoiceNumber} је издат, али је дошло до грешке приликом чувања рачуна`;
					if (saved) {
						description = t`Апликација ће покушати да сачува рачун.`;
					} else {
						description = t`Апликација због непредвиђене грешке не може сачувати рачун.`;
					}
				}

				description += t` Обавештени смо о грешци и радимо на њеном исправљању.`;
				StaticComponents.notification.warning({
					message: message,
					description: (
						<>
							{description}
							{(payment.change as number) > 0 &&
								invoiceRequest.invoiceType !== InvoiceType.COPY && (
									<>
										<br />
										<br />
									</>
								)}
							{payment.change && invoiceRequest.invoiceType !== InvoiceType.COPY
								? t`Извршите повраћај у износу од ${numberFormat(
										payment.change,
										true,
										2,
										true
								  )}`
								: ''}
						</>
					),
					duration: 10,
				});
				console.error(e);
				Bugsnag.addMetadata('receipt', {
					invoiceRequest: JSON.stringify(invoiceRequest),
					invoiceResponse: JSON.stringify(invoiceResponse),
					receiptData: JSON.stringify(receiptData),
				});
				Bugsnag.notify(e);
				Bugsnag.clearMetadata('receipt');
				return {
					invoice: {
						...(invoiceResponse || {}),
					},
				};
			}
		}
	}
	@flow.bound
	*createInvoiceV2(
		request: CreateInvoiceRequest,
		output: CreateInvoiceOutput,
		includeSignature = false,
		lastAdvanceSale?: Partial<Receipt>,
		posTerminalResponse?: any,
		customer?: Customer
	) {
		const sdc = stores.sdc;
		const user = stores.users.authenticatedUser;
		const payment = request.payments;
		const requestPayment = Object.entries(payment)
			.filter(([, value]) => value > 0)
			.map(([key, value]) => ({
				amount: value,
				paymentType: PAYMENT_TYPE_FROM_STRING[key],
			}));

		const invoiceRequest = {
			invoiceType: request.invoiceType,
			transactionType: request.transactionType,
			payment:
				requestPayment.length > 0
					? requestPayment
					: [
							{
								amount: 0,
								paymentType: PaymentType.CASH,
							},
					  ],
			paymentChange: request.change || 0,
			cashier: user.fullName,
			invoiceNumber: POS_NO,
			buyerId: request.buyerId ? request.buyerId : undefined,
			buyerCostCenterId: request.buyerCostCenterId
				? request.buyerCostCenterId
				: undefined,
			referentDocumentNumber: request.referentDocumentNumber,
			referentDocumentDT: request.referentDocumentDT,
			dateAndTimeOfIssue: request.posTime ? dayjs(request.posTime) : undefined,
			items: request.items.map((item) => ({
				id: item.variant ? item.variant.id : item.product.id,
				gtin: (item.product.ean || '').trim() !== '' ? item.product.ean : null,
				name: `${item.product.name}${
					item.variant?.variantName ? ` ${item.variant?.variantName}` : ''
				}`,
				unit: item.variant
					? item.variant.saleUnit?.label
					: item.product?.saleUnit?.label || '',
				isPieceUnitOfMeasure: item.variant
					? item.variant.saleUnit?.isPieceUnitOfMeasure
					: item.product?.saleUnit?.isPieceUnitOfMeasure,
				sku: item.variant ? item.variant.sku : item.product.sku,
				quantity: round(item.quantity, 3),
				unitPrice: round(item.finalPrice, 2),
				labels: [item.taxRateLabel],
				totalAmount: round(
					evaluate('quantity * price', {
						quantity: bignumber(round(item.quantity, 3)),
						price: bignumber(item.finalPrice),
					}).toNumber(),
					2
				),
				discount: item.discount,
			})),
			options: {
				omitQRCodeGen: '0',
				omitTextualRepresentation: '0',
			} as InvoiceData['options'],
		};
		let receiptData;
		let invoiceResponse;
		let saved = false;
		let sent = false;
		let html;
		try {
			while (deferredCreateReceipt) {
				yield deferredCreateReceipt.promise;
			}
			deferredCreateReceipt = new Deferred();
			invoiceResponse = yield sdc.createInvoice(invoiceRequest);

			receiptData = {
				tin: invoiceResponse.tin,
				businessName: invoiceResponse.businessName,
				locationName: invoiceResponse.locationName,
				address: invoiceResponse.address,
				district: invoiceResponse.district,
				totalAmount: invoiceResponse.totalAmount,
				payment: invoiceRequest.payment.map((payment) => ({
					...payment,
					amount:
						payment.paymentType === PaymentType.CASH
							? Number(payment.amount) + Number(invoiceRequest.paymentChange)
							: Number(payment.amount),
					paymentType: PAYMENT_TYPE_MAP[payment.paymentType],
				})),
				paymentChange: Number(invoiceRequest.paymentChange),
				invoiceType: INVOICE_TYPE_MAP[invoiceRequest.invoiceType],
				transactionType: TRANSACTION_TYPE_MAP[invoiceRequest.transactionType],
				invoiceNumber: invoiceResponse.invoiceNumber,
				buyerId: invoiceRequest.buyerId,
				buyerCostCenterId: invoiceRequest.buyerCostCenterId,
				referentDocumentNumber: invoiceRequest.referentDocumentNumber,
				referentDocumentDT: invoiceRequest.referentDocumentDT,
				items: invoiceRequest.items,
				additionalText: request.additionalText,
				unknownAmountAdvance: request.unknownAmountAdvance,
				advanceItems: request.advanceItems
					? request.advanceItems.map((item) => ({
							id: item.variant ? item.variant.id : item.product.id,
							gtin:
								(item.product.ean || '').trim() !== ''
									? item.product.ean
									: null,
							name: `${item.product.name}${
								item.variant?.variantName ? ` ${item.variant?.variantName}` : ''
							}`,
							unit: item.variant
								? item.variant.saleUnit?.label
								: item.product?.saleUnit?.label || '',
							isPieceUnitOfMeasure: item.variant
								? item.variant.saleUnit?.isPieceUnitOfMeasure
								: item.product?.saleUnit?.isPieceUnitOfMeasure,
							sku: item.variant ? item.variant.sku : item.product.sku,
							quantity: round(item.quantity, 3),
							unitPrice: round(item.finalPrice, 2),
							labels: [item.taxRateLabel],
							totalAmount: round(
								evaluate('quantity * price', {
									quantity: bignumber(round(item.quantity, 3)),
									price: bignumber(item.finalPrice),
								}).toNumber(),
								2
							),
					  }))
					: null,
				posNumber: invoiceRequest.invoiceNumber,
				posTime: invoiceRequest.dateAndTimeOfIssue,
				sdcTime: invoiceResponse.sdcDateTime,
				verificationUrl: invoiceResponse.verificationUrl,
				taxItems: invoiceResponse.taxItems,
				invoiceCounter: invoiceResponse.invoiceCounter,
				storeId: stores.stores.currentStoreId,
				...(typeof request.advancePayments !== 'undefined'
					? { advanceData: { advancePayments: request.advancePayments } }
					: {}),
				sdcRequest: invoiceRequest,
				sdcResponse: invoiceResponse,
				// emails: output.email ? [output.email] : [],
				// receiptHTML: html,
				posTerminalResponse,
				customerId: customer?.id,
			};

			setTimeout(() => {
				this.saveWithFailHandler(`/receipts`, receiptData);
			});
			saved = true;

			const element = document.createElement('div');
			let root;

			if (
				output.a4 ||
				output.email ||
				output.pdf ||
				output.base64pdf ||
				output.html
			) {
				const a4Journal = new A4Journal(
					invoiceRequest,
					invoiceResponse,
					includeSignature,
					request.advancePayments,
					request.advanceItems,
					lastAdvanceSale,
					request.additionalText,
					request.unknownAmountAdvance
				);
				root = createRoot(element);
				root.render(a4Journal.getComponent());

				yield a4Journal.getRenderDeferred();
			}

			document.querySelector('#a4-temporary').innerHTML = element.innerHTML;
			html = element.innerHTML;

			if (output.a4) {
				// setTimeout(() => {
				printjs({
					printable: 'a4-temporary',
					type: 'html',
					targetStyles: ['*'],
					font_size: '',
				});
				// });
			}

			if (output.thermal && stores.devices.thermalPrinters?.[0]) {
				setTimeout(async () => {
					const posTerminalConfiguration = stores.devices.posTerminals?.[0]
						?.configuration as PosTerminalDeviceConfiguration;

					const thermalJournal = new ThermalJournal(
						invoiceRequest,
						invoiceResponse,
						includeSignature,
						request.advancePayments,
						request.advanceItems,
						lastAdvanceSale,
						request.additionalText,
						request.unknownAmountAdvance
					);

					const cut =
						posTerminalResponse &&
						posTerminalConfiguration?.printOnThermalPrinter
							? 'PAPER_NO_CUT'
							: 'PAPER_FULL_CUT';

					await thermalJournal.print(
						posTerminalResponse &&
							posTerminalConfiguration?.printOnThermalPrinter &&
							posTerminalConfiguration?.printSeparately
							? 'PAPER_PART_CUT'
							: cut,
						true,
						true,
						!posTerminalResponse ||
							(posTerminalResponse &&
								posTerminalConfiguration?.printOnThermalPrinter &&
								posTerminalConfiguration?.printSeparately)
					);

					if (
						output.thermal &&
						posTerminalResponse &&
						posTerminalConfiguration?.printOnThermalPrinter
					) {
						const thermalPosTerminalBuyer = new ThermalPosTerminal(
							stores.stores.currentStore,
							posTerminalResponse,
							'buyer'
						);
						await thermalPosTerminalBuyer.print();
						if (
							posTerminalResponse.SignatureLinePrintFlag ||
							!posTerminalConfiguration?.printOnlyWhenSignatureIsRequired
						) {
							const thermalPosTerminalAcquirer = new ThermalPosTerminal(
								stores.stores.currentStore,
								posTerminalResponse,
								'acquirer'
							);
							await thermalPosTerminalAcquirer.print(
								undefined,
								undefined,
								undefined,
								false
							);
						}
					}
				});
			}

			if (output.email) {
				this.saveWithFailHandler(`/receipts/send-email`, {
					email: output.email,
					data: html,
					invoiceNumber: invoiceResponse.invoiceNumber,
					verificationUrls: [invoiceResponse.verificationUrl],
				});
				sent = true;
			}

			const pdf =
				output.pdf || output.base64pdf
					? yield html2pdf()
							.set({
								margin: 1,
								filename: `${invoiceResponse.invoiceNumber}.pdf`,
								pageBreak: {
									mode: 'css',
									after: ['.page'],
								},
								html2canvas: { scale: 2 },
								jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait' },
							})
							.from(html)
							.toPdf()
							.output('datauristring')
					: null;

			deferredCreateReceipt.resolve();
			deferredCreateReceipt = null;
			StaticComponents.notification.success({
				message: t`Рачун ${invoiceResponse.invoiceNumber} је успешно издат`,
				description:
					request.change && invoiceRequest.invoiceType !== InvoiceType.COPY
						? t`Извршите повраћај у износу од ${numberFormat(
								request.change,
								true,
								2,
								true
						  )}`
						: '',
				duration: 10,
			});

			if (
				output.a4 ||
				output.email ||
				output.pdf ||
				output.base64pdf ||
				output.html
			) {
				try {
					root.unmount();
				} catch (unmountError) {
					Bugsnag.notify(unmountError);
				}
			}

			return {
				invoice: {
					...invoiceResponse,
					...(output.pdf ? { pdf } : {}),
					...(output.base64pdf ? { base64pdf: pdf.split(',')[1] } : {}),
					...(output.html ? { html: html } : {}),
				},
			};
		} catch (e) {
			while (deferredCreateReceipt) {
				deferredCreateReceipt.reject();
				deferredCreateReceipt = null;
			}
			if (invoiceResponse) {
				let message = '';
				let description = '';
				if ((output.a4 || output.thermal) && output.email) {
					message = t`Рачун ${invoiceResponse.invoiceNumber} је издат, али је дошло до грешке приликом штампања или слања путем електронске поште`;
					if (saved && sent) {
						description = t`Апликација ће покушати да сачува и поново пошаље рачун.`;
					} else if (saved && !sent) {
						description = t`Апликација ће покушати да сачува рачун, али ћете морати ручно да пошаљете рачун путем електронске поште.`;
					} else {
						description = t`Апликација због непредвиђене грешке не може сачувати рачун.`;
					}
				} else if (output.a4 || output.thermal) {
					message = t`Рачун ${invoiceResponse.invoiceNumber} је издат, али је дошло до грешке приликом штампања.`;
					if (saved) {
						description = t`Апликација ће покушати да сачува рачун.`;
					} else {
						description = t`Апликација због непредвиђене грешке не може сачувати рачун.`;
					}
				} else if (output.email) {
					message = t`Рачун ${invoiceResponse.invoiceNumber} је издат, али је дошло до грешке приликом слања путем електронске поште`;
					if (saved && sent) {
						description = t`Апликација ће покушати да сачува и поново пошаље рачун.`;
					} else if (saved && !sent) {
						description = t`Апликација ће покушати да сачува рачун, али ћете морати ручно да пошаљете рачун путем електронске поште.`;
					} else {
						description = t`Апликација због непредвиђене грешке не може сачувати рачун.`;
					}
				} else {
					message = t`Рачун ${invoiceResponse.invoiceNumber} је издат, али је дошло до грешке приликом чувања рачуна`;
					if (saved) {
						description = t`Апликација ће покушати да сачува рачун.`;
					} else {
						description = t`Апликација због непредвиђене грешке не може сачувати рачун.`;
					}
				}

				description += t` Обавештени смо о грешци и радимо на њеном исправљању.`;
				StaticComponents.notification.warning({
					message: message,
					description: (
						<>
							{description}
							{(request.change as number) > 0 &&
								invoiceRequest.invoiceType !== InvoiceType.COPY && (
									<>
										<br />
										<br />
									</>
								)}
							{request.change && invoiceRequest.invoiceType !== InvoiceType.COPY
								? t`Извршите повраћај у износу од ${numberFormat(
										request.change,
										true,
										2,
										true
								  )}`
								: ''}
						</>
					),
					duration: 10,
				});
				console.error(e);
				Bugsnag.addMetadata('receipt', {
					invoiceRequest: JSON.stringify(invoiceRequest),
					invoiceResponse: JSON.stringify(invoiceResponse),
					receiptData: JSON.stringify(receiptData),
				});
				Bugsnag.notify(e);
				Bugsnag.clearMetadata('receipt');
				return {
					invoice: {
						...(invoiceResponse || {}),
					},
					...(output.html ? { html: html } : {}),
				};
			}

			throw e;
		}
	}

	@flow.bound
	*fiscalize(
		localSale: LocalSale,
		buyerId: string | void,
		buyerCostCenterId: string | void,
		output: DraftReceiptDelivery
	) {
		const { total, payment, invoiceType } = localSale;

		const cashSum = payment.reduce((sum, payment) => {
			if (payment.paymentType === 'cash') {
				return round(
					evaluate('sum + amount', {
						sum: bignumber(sum),
						amount: bignumber(payment.amount),
					}).toNumber(),
					2
				);
			}
			return sum;
		}, 0);
		const otherPaymentMethodsSum = payment.reduce((sum, payment) => {
			if (payment.paymentType !== 'cash') {
				return round(
					evaluate('sum + amount', {
						sum: bignumber(sum),
						amount: bignumber(payment.amount),
					}).toNumber(),
					2
				);
			}
			return sum;
		}, 0);

		const totalSum = round(
			evaluate('cashSum + otherPaymentMethodsSum', {
				cashSum: bignumber(cashSum),
				otherPaymentMethodsSum: bignumber(otherPaymentMethodsSum),
			}).toNumber(),
			2
		);

		if (totalSum === 0) {
			throw new Error('ERROR_BAD_REQUEST_NO_PAYMENT_METHOD_SPECIFIED');
		}

		if (otherPaymentMethodsSum > total) {
			throw new Error(
				'ERROR_BAD_REQUEST_SUM_NON_CASH_PAYMENT_METHODS_GREATER_THAN_TOTAL'
			);
		}

		if (invoiceType !== 'advance') {
			if (totalSum < total) {
				throw new Error(
					'ERROR_BAD_REQUEST_SUM_PAYMENT_METHODS_LESS_THAN_TOTAL'
				);
			}
		}

		const change =
			totalSum > total
				? round(
						evaluate('totalSum - total', {
							totalSum: bignumber(totalSum),
							total: bignumber(total),
						}).toNumber(),
						2
				  )
				: 0;

		const paidCash = round(
			evaluate('cashSum - change', {
				cashSum: bignumber(cashSum),
				change: bignumber(change),
			}).toNumber(),
			2
		);

		const mappedPayments: Record<string, number> = payment.reduce(
			(payments, payment) => {
				payments[payment.paymentType] = payments[payment.paymentType] || 0;
				payments[payment.paymentType] = round(
					evaluate('payments + amount', {
						payments: bignumber(payments[payment.paymentType]),
						amount: bignumber(payment.amount),
					}).toNumber(),
					2
				);
				return payments;
			},
			{}
		);

		if (paidCash > 0) {
			mappedPayments.cash = paidCash;
		}

		if (change > 0) {
			mappedPayments.change = change;
		}

		const mappedItems = localSale.itemsAsArray.map((item) => ({
			...item,
			product: item.product,
			variant: item.variant,
			taxRateLabel: localSale.vatExempt // TODO: handle this
				? TAX_FREE_LABEL
				: item.product.taxRateLabel,
			finalPrice: round(
				evaluate('price - (price * discount) / 100', {
					price: bignumber(item.finalPrice),
					// discount: bignumber(item.discount),
					discount: 0,
				}).toNumber(),
				2
			),
			discount: round(
				evaluate(
					'priceWithoutDiscount - (finalPrice - (finalPrice * discount) / 100)',
					{
						priceWithoutDiscount: bignumber(item.priceWithoutDiscount),
						finalPrice: bignumber(item.finalPrice),
						//discount: bignumber(item.discount),
						discount: bignumber(0),
					}
				).toNumber(),
				2
			),
		}));

		// TODO: advance payment
		const { invoice } = yield this.createInvoiceV2(
			{
				invoiceType: INVOICE_TYPE_FROM_STRING[localSale.invoiceType],
				transactionType: TransactionType.SALE,
				buyerId: buyerId || undefined,
				buyerCostCenterId: buyerCostCenterId || undefined,
				payments: {
					...mappedPayments,
				},
				items: mappedItems,
			},
			output,
			false
		);

		return invoice;
	}

	@action.bound
	apiAddItem(draftId: string, item: DraftReceiptItem) {
		const localSaleStore = stores.localSales as LocalSales;
		const productStore = stores.products as Products;

		const localSale = localSaleStore.byUniqueId[draftId];

		if (!localSale) {
			throw new Error('ERROR_NOT_FOUND_DRAFT_RECEIPT_NOT_FOUND');
		}

		const product = productStore.bySku[item.sku];
		if (!product) {
			throw new SocketError('ERROR_NOT_FOUND_PRODUCT_SKU', {
				sku: item.sku,
			});
		}

		const { quantity, unitPrice } = item;

		localSale.addItem(product, quantity, unitPrice);

		return {
			id: product.id,
			sku: item.sku,
			quantity: item.quantity,
			unitPrice: item.unitPrice,
		};
	}

	@flow.bound
	*apiCreateReceipt(data: CreateDraftReceiptRequest) {
		const localSaleStore = stores.localSales as LocalSales;
		const productStore = stores.products as Products;

		// get products by sku and return an error if any of the products are not found
		const products = yield Promise.all(
			data.items.map(async (item) => {
				const product = productStore.bySku[item.sku];
				if (!product) {
					throw new SocketError('ERROR_NOT_FOUND_PRODUCT_SKU', {
						sku: item.sku,
					});
				}
				return {
					...item,
					product,
				};
			})
		);

		const localSale = localSaleStore.createSale();
		localSale.setInvoiceType(data.invoiceType);

		products.forEach(({ product, quantity, unitPrice }) => {
			localSale.addItem(product, quantity, unitPrice);
		});

		// if (data.payment) {
		// 	localSale.setPayment(data.payment);
		// }

		// if (data.status === 'draft') {
		return {
			id: localSale.uniqueId,
			items: localSale.itemsAsArray.map((item) => ({
				id: item.id,
				sku: item.product.sku,
				quantity: item.quantity,
				unitPrice: item.price,
			})),
			// receipts: [],
		};
	}

	@flow.bound
	*apiFinalize(draftId: string, data: FinalizeDraftReceiptRequest) {
		const localSaleStore = stores.localSales as LocalSales;

		const localSale = localSaleStore.byUniqueId[draftId];
		if (!localSale) {
			throw new Error('ERROR_NOT_FOUND_DRAFT_RECEIPT_NOT_FOUND');
		}

		localSale.setPayment(data.payment);

		const invoice = yield this.fiscalize(
			localSale,
			data.buyerId,
			data.buyerCostCenterId,
			{
				a4: !!data.receiptDelivery?.a4Printer,
				email: data.receiptDelivery?.email,
				thermal: !!data.receiptDelivery?.thermalPrinter,
				pdf: !!data.receiptDelivery?.pdf,
				html: !!data.receiptDelivery?.html,
			}
		);
		const id = localSale.uniqueId;
		const items = [...localSale.itemsAsArray];
		localSaleStore.removeSale(localSale);
		return {
			draftId: id,
			items: items,
			receipts: [invoice],
		};
	}

	@flow.bound
	*apiFiscalizeNormal({
		request,
		receiptDelivery,
		includeSignature,
	}: FiscalizeNormalRequest) {
		if (request.invoiceType === InvoiceType.ADVANCE) {
			request.items = request.items.map((item) => ({
				...item,
				product: {
					name: `${ADVANCE_TYPE[item.taxRateLabel]}: ${
						locales[stores.stores.currentStore?.language || 'sr-Cyrl-RS']
							.advance
					}`,
					id: null,
					ean: null,
					sku: null,
				},
			}));
		}

		request.referentDocumentDT = request.referentDocumentDT
			? dayjs(request.referentDocumentDT)
			: undefined;
		request.posTime = request.posTime ? dayjs(request.posTime) : undefined;

		const { invoice } = yield this.createInvoiceV2(
			request,
			receiptDelivery,
			includeSignature
		);
		return {
			receipts: [invoice],
		};
	}
	@flow.bound
	*apiFiscalizeAddAdvancePayment({
		request,
		receiptDelivery,
		includeSignature,
	}: FiscalizeNormalRequest) {
		request.items = request.items.map((item) => ({
			...item,
			product: {
				name: `${ADVANCE_TYPE[item.taxRateLabel]}: ${
					locales[stores.stores.currentStore?.language || 'sr-Cyrl-RS'].advance
				}`,
				id: null,
				ean: null,
				sku: null,
			},
		}));

		request.referentDocumentDT = request.referentDocumentDT
			? dayjs(request.referentDocumentDT)
			: undefined;
		request.posTime = request.posTime ? dayjs(request.posTime) : undefined;

		const { invoice } = yield this.createInvoiceV2(
			request,
			receiptDelivery,
			includeSignature
		);
		return {
			receipts: [invoice],
		};
	}
	@flow.bound
	*apiFiscalizeFinalizeAdvanceRefund({
		request,
		receiptDelivery,
		includeSignature,
	}: FiscalizeNormalRequest) {
		request.items = request.items.map((item) => ({
			...item,
			product: {
				name: `${ADVANCE_TYPE[item.taxRateLabel]}: ${
					locales[stores.stores.currentStore?.language || 'sr-Cyrl-RS'].advance
				}`,
				id: null,
				ean: null,
				sku: null,
			},
		}));

		request.referentDocumentDT = request.referentDocumentDT
			? dayjs(request.referentDocumentDT)
			: undefined;
		request.posTime = request.posTime ? dayjs(request.posTime) : undefined;

		const { invoice } = yield this.createInvoiceV2(
			request,
			receiptDelivery,
			includeSignature
		);
		return {
			receipts: [invoice],
		};
	}

	@flow.bound
	*apiFiscalizeFinalizeAdvance({
		request,
		receiptDelivery,
		includeSignature,
		lastAdvanceSale,
	}: FiscalizeNormalRequest) {
		request.referentDocumentDT = request.referentDocumentDT
			? dayjs(request.referentDocumentDT)
			: undefined;
		request.posTime = request.posTime ? dayjs(request.posTime) : undefined;
		lastAdvanceSale.sdcTime = dayjs(lastAdvanceSale.sdcTime);

		const { invoice } = yield this.createInvoiceV2(
			request,
			receiptDelivery,
			includeSignature,
			lastAdvanceSale
		);
		return {
			receipts: [invoice],
		};
	}

	@flow.bound
	*apiFiscalizeCopy({
		request,
		receiptDelivery,
		includeSignature,
		lastAdvanceSale,
	}: FiscalizeNormalRequest) {
		request.referentDocumentDT = request.referentDocumentDT
			? dayjs(request.referentDocumentDT)
			: undefined;
		request.posTime = request.posTime ? dayjs(request.posTime) : undefined;
		if (lastAdvanceSale) {
			lastAdvanceSale.sdcTime = dayjs(lastAdvanceSale.sdcTime);
		}

		request.items = request.items.map((item) =>
			item.isAdvance
				? {
						...item,
						product: {
							name: `${ADVANCE_TYPE[item.taxRateLabel]}: ${
								locales[stores.stores.currentStore?.language || 'sr-Cyrl-RS']
									.advance
							}`,
							id: null,
							ean: null,
							sku: null,
						},
				  }
				: item
		);

		const { invoice } = yield this.createInvoiceV2(
			request,
			receiptDelivery,
			includeSignature,
			lastAdvanceSale
		);
		return {
			receipts: [invoice],
		};
	}

	@flow.bound
	*apiFiscalizeRefund({
		request,
		receiptDelivery,
		includeSignature,
	}: FiscalizeNormalRequest) {
		request.referentDocumentDT = request.referentDocumentDT
			? dayjs(request.referentDocumentDT)
			: undefined;
		request.posTime = request.posTime ? dayjs(request.posTime) : undefined;

		request.items = request.items.map((item) =>
			item.isAdvance
				? {
						...item,
						product: {
							name: `${ADVANCE_TYPE[item.taxRateLabel]}: ${
								locales[stores.stores.currentStore?.language || 'sr-Cyrl-RS']
									.advance
							}`,
							id: null,
							ean: null,
							sku: null,
						},
				  }
				: item
		);

		const { invoice } = yield this.createInvoiceV2(
			request,
			receiptDelivery,
			includeSignature
		);
		return {
			receipts: [invoice],
		};
	}

	async afterAuth(authenticated: boolean) {
		if (authenticated && this.syncQueue.length > 0) {
			this.failedSyncing.push(...this.syncQueue);
			this.syncQueue = [];
		}

		if (authenticated && this.failedSyncing.length > 0) {
			this.failedSyncing = this.failedSyncing.filter((receipt) => {
				if (receipt.url.startsWith('/receipts')) {
					return !!receipt.data.tin;
				}

				return true;
			});

			if (!retryInterval && this.failedSyncing.length > 0) {
				retryInterval = setInterval(() => {
					this.retryFailed();
				}, 60000);
			}
		}
	}
}

export { Receipts, Receipt };
