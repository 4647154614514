import { t } from '@lingui/macro';
import { Badge, DatePicker, Input } from 'antd';
import dayjs from 'dayjs';

import ConversionCreateButton from './Components/CreateButton';
import { CustomEmpty } from './Components/CustomEmpty';
import { EditButtons } from './Components/EditButtons';
import ConversionsItemsInput from './Components/ConversionItems';
import { CreatePage } from '../../../../components/Page';
import numberFormat from '../../../../lib/numberFormat';
import { StockTurnoverStatus } from '../../../../stores/StockTurnover.mobx';
import {
	Conversion,
	ConversionItem,
	ConversionType,
} from '../../../../stores/Conversion.mobx';

export default () =>
	CreatePage({
		module: 'stocks',
		submodule: 'conversion',
		model: 'conversion',
		view: {
			useEdit: true,
		},
		edit: {
			width: 1800,
			beforeSave: (entity) => {
				return {
					...entity,
					items: entity.items.filter(
						(item: ConversionItem) =>
							item.warehouseId && item.productId && item.convertedProductId
					),
					number: undefined,
				};
			},
			disabled: (record: Conversion) => {
				return (
					record?.stockTurnoverItem?.status === StockTurnoverStatus.CONFIRMED
				);
			},
			disabledReason: (entity) => {
				return t`Конверзија је потврђена и није могуће вршити измене.`;
			},
			title: {
				new: t`Додавање конверзије`,
				existing: (entity) => t`Измена конверзије ${entity?.number || ''}`,
			},
			shouldFetch: true,
			buttons: (props) => <EditButtons {...props} />,
			fields: (record, form, setFields) => {
				return [
					{
						key: 'basic',
						label: t`Основне информације`,
						fields: [
							{
								key: 'number',
								label: t`Број конверзије`,
								xs: 24,
								sm: 4,
								component: <Input disabled />,
							},
							{
								key: 'date',
								label: t`Датум`,
								xs: 24,
								sm: 4,
								component: <DatePicker style={{ width: '100%' }} format="L" />,
								initialValue: dayjs(),
								rules: [
									{
										required: true,
										message: t`Датум је обавезан`,
									},
								],
							},
						],
					},
					{
						key: 'items',
						label: t`Ставке конверзије`,
						fields: [
							{
								key: 'items',
								component: <ConversionsItemsInput />,
								span: 24,
							},
						],
					},
				];
			},
		},
		page: {
			shouldFetch: true,
			searchable: false,
			empty: {
				customEmptyComponent: <CustomEmpty />,
			},
			createButtonText: t`Додај конверзију`,
			deletePrompt: t`Да ли сте сигурни да желите да обришете ову конверзију?`,
			table: {
				columns: () => [
					{
						title: t`Број`,
						dataIndex: 'number',
						key: 'number',
						width: 120,
						shouldLink: true,
					},
					{
						title: t`Статус`,
						dataIndex: ['stockTurnoverItem', 'status'],
						key: 'status',
						render: (status) => {
							if (status === StockTurnoverStatus.DRAFT) {
								return <Badge color="lightGrey" text={t`Нацрт`} />;
							}

							if (status === StockTurnoverStatus.CONFIRMED) {
								return <Badge color="green" text={t`Потврђена`} />;
							}
						},
						width: 120,
					},
					{
						title: t`Врста`,
						dataIndex: 'type',
						key: 'type',
						ellipsis: true,
						render: (type, record) => {
							if (type === ConversionType.MANUAL) {
								return t`Ручно креирана`;
							}
							if (type === ConversionType.INVENTORY) {
								return t`Аутоматски креирана из пописа`;
							}

							return t`Непознато`;
						},
					},
					{
						title: t`Датум`,
						dataIndex: 'date',
						key: 'date',
						render: (date) => {
							return date.format('L');
						},
						width: 120,
					},
					{
						title: t`Набавна вредност`,
						dataIndex: ['stockTurnoverItem', 'purchaseAmount'],
						key: 'purchaseAmount',
						render: (value) => {
							return numberFormat(value, false, 2, true);
						},
						width: 150,
					},

					{
						title: t`Продајна вредност`,
						dataIndex: ['stockTurnoverItem', 'saleAmount'],
						key: 'saleAmount',
						render: (value) => {
							return numberFormat(value, false, 2, true);
						},
						width: 150,
					},
				],
			},
			customCreateButton: () => <ConversionCreateButton />,
		},
	});
