import type { EditableFormInstance } from '@ant-design/pro-table/es';
import { t } from '@lingui/macro';
import { Divider, Input, InputNumber, Tag, Typography } from 'antd';
import { round } from 'lodash';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import styles from './WriteOffItems.module.less';
import ProductInput from '../../../../../../components/Inputs/ProductInput';
import WarehouseSelect from '../../../../../../components/Selects/WarehouseSelect';
import { TableInputProTable } from '../../../../../../components/TableInputProTable';
import numberFormat from '../../../../../../lib/numberFormat';
import stores from '../../../../../../stores/index.mobx';
import { bignumber, evaluate } from 'mathjs';
import { WriteOffItem } from '../../../../../../stores/WriteOff.mobx';
type Props = {
	value?: WriteOffItem[];
};

function WriteOffItemsInput({ value, ...props }: Props) {
	const {
		writeOffs: { fetchQuantityAndPrice, isFetchingQuantityAndPrice },
		warehouses: { byId: storesById, available: availableWarehouses },
		products: { byId: productsById },
	} = stores;

	const editableFormRef = useRef<EditableFormInstance>();
	const [initialValue, setInitialValue] = useState<Partial<WriteOffItem>>({});

	useEffect(() => {
		if (availableWarehouses?.length === 1) {
			setInitialValue({
				warehouseId: availableWarehouses[0]?.id,
			});
		}
	}, [availableWarehouses]);

	const onSelectWarehouse = useCallback((warehouseId) => {
		setInitialValue({
			warehouseId,
		});
	}, []);

	const columns = useMemo(() => {
		return [
			{
				title: t`Складиште`,
				width: 150,
				dataIndex: 'warehouseId',
				formItemProps: {
					rules: [
						{
							required: true,
							message: t`Складиште је обавезно`,
						},
					],
				},
				renderFormItem: (
					{ handleKeyDown },
					{ recordKey, isEditable, record }
				) => {
					return isEditable ? (
						<WarehouseSelect
							popupMatchSelectWidth={false}
							onKeyDown={(e) => handleKeyDown(e, recordKey)}
							onSelect={onSelectWarehouse}
							onChange={(value) => {
								setTimeout(async () => {
									const product = productsById[record.productId];
									if (!product) {
										return;
									}

									const quantity = 0;
									let purchasePrice = 0;
									let salePriceWithVat = 0;
									let productMaxQuantity = 0;

									if (value) {
										const quantityAndPrice = await flowResult(
											fetchQuantityAndPrice(product.id, value)
										);

										if (quantityAndPrice) {
											productMaxQuantity = quantityAndPrice.quantity || 0;
											purchasePrice =
												quantityAndPrice.averagePurchasePrice || 0;
											salePriceWithVat = quantityAndPrice.salePriceWithVat || 0;
										}
									}

									const productUnit = product?.unit?.name || `kom`;

									const saleAmountWithVat = round(
										evaluate('salePriceWithVat * quantity', {
											salePriceWithVat: bignumber(salePriceWithVat),
											quantity: bignumber(quantity),
										}).toNumber(),
										4
									);

									editableFormRef.current?.setRowData?.(recordKey, {
										salePriceWithVat,
										productUnit,
										quantity,
										productMaxQuantity,
										purchasePrice,
										saleAmountWithVat,
									});
								}, 100);
							}}
						/>
					) : (
						<Input />
					);
				},
				render: (value, record, index) => {
					return storesById[value]?.name;
				},
			},
			{
				dataIndex: 'productMaxQuantity',
				editable: true,
				renderFormItem: () => <Input disabled />,
				hideInTable: true,
			},
			{
				title: t`Артикал`,
				dataIndex: 'productId',
				formItemProps: {
					rules: [
						{
							required: true,
							message: t`Артикал је обавезан`,
						},
					],
				},
				renderFormItem: (
					{ handleKeyDown },
					{ isEditable, record, recordKey }
				) => {
					return isEditable ? (
						<ProductInput
							onKeyDown={(e) => handleKeyDown(e, recordKey)}
							onChange={(value) => {
								setTimeout(async () => {
									const product = productsById[value];
									if (!product) {
										return;
									}

									const quantity = 0;
									let purchasePrice = 0;
									let salePriceWithVat = 0;
									let productMaxQuantity = 0;

									if (record.warehouseId) {
										const quantityAndPrice = await flowResult(
											fetchQuantityAndPrice(product.id, record.warehouseId)
										);

										if (quantityAndPrice) {
											productMaxQuantity = quantityAndPrice.quantity || 0;
											purchasePrice =
												quantityAndPrice.averagePurchasePrice || 0;
											salePriceWithVat = quantityAndPrice.salePriceWithVat || 0;
										}
									}

									const productUnit = product?.unit?.name || `kom`;

									const saleAmountWithVat = round(
										evaluate('salePriceWithVat * quantity', {
											salePriceWithVat: bignumber(salePriceWithVat),
											quantity: bignumber(quantity),
										}).toNumber(),
										4
									);

									editableFormRef.current?.setRowData?.(recordKey, {
										salePriceWithVat,
										productUnit,
										quantity,
										productMaxQuantity,
										purchasePrice,
										saleAmountWithVat,
									});
								}, 100);
							}}
						/>
					) : (
						<Input />
					);
				},
				render: (value, record, index) => {
					const product = productsById[value];
					return product?.parent ? (
						<>
							<Typography.Text type="secondary">{product.sku}</Typography.Text>
							<Divider type="vertical" />
							{product.parent.name}&nbsp;&nbsp;
							<Tag color="magenta" bordered={false}>
								{product.variantName}
							</Tag>
						</>
					) : (
						<>
							<Typography.Text type="secondary">{product?.sku}</Typography.Text>
							<Divider type="vertical" />
							{product?.name}
						</>
					);
				},
			},
			{
				title: t`Количина`,
				dataIndex: 'quantity',
				width: 130,
				align: 'right',
				formItemProps: (_, config) => {
					return {
						initialValue: 0,
						rules: [
							{
								required: true,
								message: t`Количина је обавезна`,
							},
							({ getFieldValue }) => ({
								type: 'number',
								validator: async (_, value) => {
									const maxQuantity = getFieldValue([
										config.entity.id,
										'productMaxQuantity',
									]);
									if (value > maxQuantity) {
										return Promise.reject(
											t`Количина мора бити мања или једнака ${maxQuantity}`
										);
									}

									if (value > 0) {
										return Promise.resolve();
									}

									return Promise.reject(t`Количина мора бити већа од 0`);
								},
							}),
						],
					};
				},
				renderFormItem: (
					{ handlePressEnter, handleKeyDown },
					{ isEditable, record, recordKey }
				) => {
					return isEditable ? (
						<InputNumber
							type="number"
							controls={false}
							style={{ width: '100%' }}
							suffix={record.productUnit}
							onPressEnter={(e) => handlePressEnter(e, recordKey)}
							onKeyDown={(e) => handleKeyDown(e, recordKey)}
							onChange={(value) => {
								setTimeout(async () => {
									const quantity = Number(value);
									const salePriceWithVat = record.salePriceWithVat || 0;

									const saleAmountWithVat = round(
										evaluate('salePriceWithVat * quantity', {
											salePriceWithVat: bignumber(salePriceWithVat),
											quantity: bignumber(quantity),
										}).toNumber(),
										4
									);
									editableFormRef.current?.setRowData?.(recordKey, {
										saleAmountWithVat,
									});
								}, 100);
							}}
						/>
					) : (
						<Input />
					);
				},
				render: (value: string, record) => {
					return `${numberFormat(value, false, 3, false)} ${
						record.productUnit
					}`;
				},
			},
			{
				title: t`Разлог`,
				dataIndex: 'reason',
				width: 300,
				renderFormItem: (
					{ handlePressEnter, handleKeyDown },
					{ isEditable, record, recordKey }
				) => {
					return (
						<Input
							onPressEnter={(e) => handlePressEnter(e, recordKey)}
							onKeyDown={(e) => handleKeyDown(e, recordKey)}
						/>
					);
				},
				render: (value: string) => {
					return value === '-' ? '' : value;
				},
			},
			{
				title: t`Набавна цена`,
				dataIndex: 'purchasePrice',
				width: 120,
				align: 'right',
				formItemProps: {
					initialValue: 0,
				},
				renderFormItem: () => {
					return <Input disabled />;
				},
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},
			{
				title: t`Прод. цена са ПДВ`,
				dataIndex: 'salePriceWithVat',
				width: 150,
				align: 'right',
				formItemProps: {
					initialValue: 0,
				},
				renderFormItem: (
					{ handlePressEnter, handleKeyDown },
					{ isEditable, record, recordKey }
				) => {
					return isEditable ? (
						<InputNumber
							disabled
							type="number"
							controls={false}
							style={{ width: '100%' }}
							className={styles.rightAlignNumberInput}
						/>
					) : (
						<Input />
					);
				},
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},
			{
				title: t`Прод. вр. са ПДВ`,
				dataIndex: 'saleAmountWithVat',
				width: 130,
				align: 'right',
				formItemProps: {
					initialValue: 0,
				},
				renderFormItem: () => (
					<InputNumber
						disabled
						controls={false}
						className={styles.rightAlignNumberInput}
						style={{ width: '100%' }}
						onFocus={(event) => {
							event.currentTarget.select();
						}}
					/>
				),
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},
		];
	}, [fetchQuantityAndPrice, onSelectWarehouse, productsById, storesById]);

	return (
		<>
			<TableInputProTable<WriteOffItem>
				editableFormRef={editableFormRef}
				// shouldShowDuplicateButton
				shouldShowIndexColumn
				addButtonText={t`Додај артикал`}
				emptyText={t`Нема ставки`}
				iconPath="/images/icons/new/inventory.svg"
				columns={columns}
				value={value}
				initialValue={initialValue}
				tableProps={{
					loading: isFetchingQuantityAndPrice,
					scroll: {
						x: 1700,
					},
				}}
				{...props}
			/>
		</>
	);
}

export default observer(WriteOffItemsInput);
