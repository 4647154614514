import { t } from '@lingui/macro';
import { Badge, DatePicker, Input } from 'antd';
import dayjs from 'dayjs';

import { EditButtons } from './Components/EditButtons';
import StockCheckupsItemsInput from './Components/StockCheckupItems';
import { CreatePage } from '../../../../components/Page';
import { StockCheckup } from '../../../../stores/StockCheckup.mobx';
import { StockTurnoverStatus } from '../../../../stores/StockTurnover.mobx';
import StockCheckupCreateButton from './Components/CreateButton';
import numberFormat from '../../../../lib/numberFormat';
import WarehouseSelect from '../../../../components/Selects/WarehouseSelect';
import { CustomEmpty } from './Components/CustomEmpty';

export default () =>
	CreatePage({
		module: 'stocks',
		submodule: 'checkup',
		model: 'stockCheckup',
		view: {
			useEdit: true,
		},
		edit: {
			width: 1800,
			beforeSave: (entity) => {
				return {
					...entity,
					items: entity.items.filter((item) => item.productId && item.quantity),
					number: undefined,
				};
			},
			disabled: (record: StockCheckup) => {
				return record?.status === StockTurnoverStatus.CONFIRMED;
			},
			disabledReason: () => {
				return t`Попис је потврђен и није могуће вршити измене.`;
			},
			title: {
				new: t`Додавање пописа`,
				existing: (entity) => t`Измена пописа ${entity?.number || ''}`,
			},
			shouldFetch: false,
			buttons: (props) => <EditButtons {...props} />,
			fields: (record, form, setFields) => {
				console.log(record, form.getFieldsValue());
				return [
					{
						key: 'basic',
						label: t`Основне информације`,
						fields: [
							{
								key: 'number',
								label: t`Број пописа`,
								xs: 24,
								sm: 4,
								component: <Input disabled />,
							},
							{
								key: 'date',
								label: t`Датум`,
								xs: 24,
								sm: 4,
								component: <DatePicker style={{ width: '100%' }} format="L" />,
								initialValue: dayjs(),
								rules: [
									{
										required: true,
										message: t`Датум је обавезан`,
									},
								],
							},
							{
								key: 'warehouseId',
								label: t`Складиште`,
								xs: 24,
								sm: 4,
								component: <WarehouseSelect />,
								rules: [
									{
										required: true,
										message: t`Складиште је обавезно`,
									},
								],
								rerenderOnChange: true,
							},
						],
					},
					{
						key: 'items',
						label: t`Ставке пописа`,
						fields: [
							{
								key: 'items',
								component: (
									<StockCheckupsItemsInput
										warehouseId={form.getFieldValue('warehouseId')}
									/>
								),
								span: 24,
							},
						],
					},
				];
			},
		},
		page: {
			createButtonText: t`Додај попис`,
			shouldFetch: true,
			searchable: false,
			empty: {
				customEmptyComponent: <CustomEmpty />,
			},
			table: {
				columns: () => [
					{
						title: t`Број`,
						dataIndex: 'number',
						key: 'number',
						width: 120,
						shouldLink: true,
					},
					{
						title: t`Статус`,
						dataIndex: ['status'],
						key: 'status',
						render: (status) => {
							if (status === StockTurnoverStatus.DRAFT) {
								return <Badge color="lightGrey" text={t`Нацрт`} />;
							}

							if (status === StockTurnoverStatus.CONFIRMED) {
								return <Badge color="green" text={t`Потврђена`} />;
							}
						},
						width: 120,
					},
					{
						title: t`Датум`,
						dataIndex: 'date',
						key: 'date',
						render: (date) => {
							return date.format('L');
						},
						width: 120,
					},
					{
						title: t`Набавна вредност`,
						dataIndex: ['purchaseAmount'],
						key: 'purchaseAmount',
						render: (value) => {
							return numberFormat(value, false, 2, true);
						},
						width: 150,
					},

					{
						title: t`Продајна вредност`,
						dataIndex: ['saleAmount'],
						key: 'saleAmount',
						render: (value) => {
							return numberFormat(value, false, 2, true);
						},
						width: 150,
					},
				],
			},
			deletePrompt: t`Да ли сте сигурни да желите да обришете овај попис?`,
			customCreateButton: () => <StockCheckupCreateButton />,
		},
	});
