import { Dayjs } from 'dayjs';
import { flow, observable } from 'mobx';

import { CreateStore } from './Crud.mobx';
import { StockTurnover, StockTurnoverStatus } from './StockTurnover.mobx';
import { ArrayTypeTransformer } from './transformers/ArrayType';
import { DayjsTransformer } from './transformers/Dayjs';

const { Store, Entity } = CreateStore({
	name: 'write-off',
	paginated: true,
	order: 'desc',
});

export class WriteOffItem extends Entity {
	@observable productId?: string;
	@observable warehouseId?: string;
	@observable purchasePrice?: number;
	@observable quantity?: number;
	@observable purchaseAmount?: number;
	@observable salePrice?: number;
	@observable salePriceWithVat?: number;
	@observable saleAmountWithVat?: number;
	@observable productUnit?: string;
	@observable reason?: string;
	// @observable productTaxRate?: number;
	// @observable convertedProductTaxRate?: number;

	@observable productMaxQuantity?: number;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}
}

class WriteOff extends Entity {
	@observable number?: string;

	@observable stockTurnoverItem?: StockTurnover;

	@observable isConfirming = false;

	@DayjsTransformer
	@observable
	date?: Dayjs;

	@ArrayTypeTransformer(WriteOffItem)
	@observable
	items?: WriteOffItem[] = [];

	@flow.bound
	*confirm() {
		this.isConfirming = true;
		try {
			yield this.getClient().patch(`/write-offs/${this.id}/confirm`);
		} catch (e) {
			//
		} finally {
			this.isConfirming = false;
		}
	}

	@flow.bound
	*unconfirm() {
		this.isConfirming = true;
		try {
			yield this.getClient().patch(`/write-offs/${this.id}/unconfirm`);
		} catch (e) {
			throw e;
		} finally {
			this.isConfirming = false;
		}
	}

	get isDeletable() {
		return this.stockTurnoverItem?.status === StockTurnoverStatus.DRAFT;
	}

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}
}

class WriteOffs extends Store<WriteOff> {
	@observable isFetchingQuantityAndPrice = false;

	constructor() {
		super(WriteOff);
	}

	@flow.bound
	*fetchQuantityAndPrice(productId: string, warehouseId: string) {
		this.isFetchingQuantityAndPrice = true;
		try {
			const { data } = yield this.getClient().get(
				`/products/${productId}/quantity/${warehouseId}`
			);

			return data;
		} catch (e) {
			return null;
		} finally {
			this.isFetchingQuantityAndPrice = false;
		}
	}

	get isCreatable() {
		return true;
	}
}

export { WriteOffs, WriteOff };
