import { Dayjs } from 'dayjs';
import { computed, flow, observable } from 'mobx';

import { CreateStore } from './Crud.mobx';
import { StockTurnover, StockTurnoverStatus } from './StockTurnover.mobx';
import { ArrayTypeTransformer } from './transformers/ArrayType';
import { DayjsTransformer } from './transformers/Dayjs';

const { Store, Entity } = CreateStore({
	name: 'stockCheckup',
	paginated: true,
});

class StockCheckupItem extends Entity {
	@observable productId?: string;
	@observable quantity?: number;
	@observable purchasePrice?: number;
	@observable purchaseAmount?: number;
	@observable salePrice?: number;
	@observable salePriceWithVat?: number;
	@observable saleAmountWithVat?: number;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}
}

class StockCheckup extends Entity {
	@observable name?: string;
	@observable number?: string;
	@observable warehouseId?: string;
	@observable status?: StockTurnoverStatus;
	@observable purchaseAmount?: number;
	@observable saleAmount?: number;

	@observable isConfirming = false;

	@DayjsTransformer
	@observable
	date?: Dayjs;

	@ArrayTypeTransformer(StockCheckupItem)
	@observable
	items?: StockCheckupItem[] = [];

	@flow.bound
	*confirm(force = false) {
		this.isConfirming = true;
		try {
			const { data } = yield this.getClient().patch(
				`/stock-checkups/${this.id}/confirm?force=${force}`
			);
			this.replace(data);
		} catch (e) {
			throw e;
		} finally {
			this.isConfirming = false;
		}
	}

	@flow.bound
	*unconfirm() {
		this.isConfirming = true;
		try {
			const { data } = yield this.getClient().patch(
				`/stock-checkups/${this.id}/unconfirm`
			);
			this.replace(data);
		} catch (e) {
			throw e;
		} finally {
			this.isConfirming = false;
		}
	}

	get isDeletable() {
		return this?.status === StockTurnoverStatus.DRAFT;
	}

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}
}

class StockCheckups extends Store<StockCheckup> {
	@observable isFetchingQuantityAndPrice = false;

	constructor() {
		super(StockCheckup);
	}

	get isCreatable() {
		return true;
	}

	@flow.bound
	*fetchQuantityAndPrice(productId: string, warehouseId: string) {
		this.isFetchingQuantityAndPrice = true;
		try {
			const { data } = yield this.getClient().get(
				`/products/${productId}/quantity/${warehouseId}`
			);

			return data;
		} catch (e) {
			return null;
		} finally {
			this.isFetchingQuantityAndPrice = false;
		}
	}
}

export { StockCheckups, StockCheckup };
